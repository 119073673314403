import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import CustomBadge from "../../../../common/components/CustomBadge";
import CustomTable, {
  HeadCell,
} from "../../../../common/components/CustomTable/CustomTable";
import {
  IAPIResponse,
  IFilterFetch,
  ISellerListing,
} from "../../../../common/utils/interfaces";
import { getSellersList } from "../../../../../apis/admin/sellers";
import { formatDate } from "../../../../common/utils/helpers";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { useNavigate } from "react-router-dom";

const headCells: readonly HeadCell[] = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "submission_date",
    numeric: true,
    disablePadding: false,
    label: "Submission Date",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Approval Status",
    align: "center",
  },
];

const DashboardTable = ({ filter }: { filter: IFilterFetch }) => {
  const navigate = useNavigate();
  const { layoutSearchText, layoutStatusFilter } = useSelector(
    (state: RootState) => state.search
  );

  const [tableData, setTableData] = useState<ISellerListing[]>([]);

  useEffect(() => {
    fetchTableData();
  }, [layoutSearchText, layoutStatusFilter]);

  const fetchTableData = async () => {
    const result: IAPIResponse | undefined = await getSellersList({
      filter: layoutStatusFilter,
      search: layoutSearchText,
    });
    if (result) {
      const { data, count } = result;
      setTableData(data);
    }
  };
  const data: ISellerListing[] = [];

  const tableTitle = {
    mainText: "Developers",
    subText: filter.search ? `results found` : `developers`,
  };

  const getBadgeMode = (text: string) => {
    switch (text) {
      case "approved":
        return "success";

      case "rejected":
        return "error";

      default:
        return "info";
    }
  };

  const MappedTableRows = ({
    row,
    labelId,
    isItemSelected,
  }: {
    row: ISellerListing;
    labelId: string;
    isItemSelected: boolean;
  }) => (
    <>
      <TableCell padding="checkbox">
        {/* <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        /> */}
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {`${row.first_name} ${row.last_name}`}
      </TableCell>
      <TableCell align="right">
        {formatDate(row.submission_date || "")}
      </TableCell>
      <TableCell align="center">
        <CustomBadge mode={getBadgeMode(row.status)}>{row.status}</CustomBadge>
      </TableCell>
    </>
  );

  const onRowClick = (row: any) => {
    navigate("/admin/developers", {
      state: { developer: row },
    });
  };

  return (
    <CustomTable
      tableType="section-table"
      tableTitle={tableTitle}
      headCells={headCells}
      rows={tableData}
      mappedTableRows={MappedTableRows}
      hideChangePerPage
      noSelections
      onRowClick={onRowClick}
    />
  );
};

export default DashboardTable;
