import { useState } from "react";
import { Modal } from "@mui/material";
import statesData from "../../data/states.json";
import { FiberManualRecord } from "@mui/icons-material";

type CustomModalProps = {
  stateData: {
    id: number;
    name: string;
    code: string;
    number_of_listings: number;
  };
  updateList?: (id: number) => void;
  filterSelected?: boolean;
};

const StateModalUnit: React.FC<CustomModalProps> = ({
  stateData,
  updateList,
  filterSelected
}) => {
  const [isSelected, setIsSelected] = useState(filterSelected || false);
  return (
    <div
      className="flex justify-between py-[10px] px-[15px]"
      style={{ cursor: "pointer", alignItems: "center" }}
      onClick={() => {
        updateList && updateList(stateData.id);
        setIsSelected((selected) => !selected);
      }}
    >
      <span className="text-[14px]">
        {stateData.name}{" "}
        <span className="text-primary/50">
          ({stateData.number_of_listings} results)
        </span>
      </span>
      {isSelected && (
        <FiberManualRecord style={{ fontSize: 14, color: "green" }} />
      )}
    </div>
  );
};

export default StateModalUnit;

function searchByKeys(array: any[], keys: string[]) {
  return array.filter((obj) => keys.some((key) => key in obj));
}
