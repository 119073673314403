import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../types";
import React, { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { IListing } from "../../common/utils/interfaces";

interface Props extends CustomModalProps {
  enlargeImage?: (a: number) => void;
  filesList?: IListing | null;
}
const ImageGallery: React.FC<Props> = ({
  handleClose,
  open,
  submitAction,
  enlargeImage,
  filesList,
}) => {
  const listing_ = useSelector((state: RootState) => state.listing.listing);
  const listing = filesList || listing_;

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="bg-white h-[90vh] w-[90vw] sm:w-[80vw] mx-auto mt-[5vh] overflow-y-scroll outline-none">
        <div className="flex sticky top-0 bg-white items-center py-[26px] px-6 sm:px-[100px] text-primary card-shadow">
          <img src="/assets/images/chevron-left.svg" alt="" />
          <button className="ml-[14px] mr-auto" onClick={handleClose}>
            Back to Listing
          </button>
          {/* <div className="hidden sm:contents">
            <div className="flex items-center gap-x-[18px]">
              <img src="/assets/images/heart.svg" alt="" />
              <div className="flex items-center gap-x-[6px]">
                <img src="/assets/images/flag.svg" alt="" />
                <span>Report</span>
              </div>
              <div className="flex items-center gap-x-[6px]">
                <img src="/assets/images/print.svg" alt="" />
                <span>Print</span>
              </div>
              <div className="flex items-center gap-x-[6px]">
                <img src="/assets/images/share.svg" alt="" />
                <span>Share</span>
              </div>
            </div>
          </div> */}
        </div>
        <div className="flex flex-col gap-y-6 sm:gap-y-[35px] px-6 sm:px-10 pb-10 lg:px-[100px] mt-[30px]">
          {listing?.files.map((file, index) => {
            console.log("aklsfj", file);
            return (
              <div
                onClick={() => enlargeImage && enlargeImage(index)}
                className="h-[224px] sm:h-[447px] cursor-pointer rounded-lg relative"
              >
                {file.caption && (
                  <div className="absolute z-[100] rounded-lg py-4 px-8 left-[50%] translate-x-[-50%] top-4 bg-black opacity-60">
                    <p className="text-white">{file.caption}</p>
                  </div>
                )}
                {file.file_type === "image" && (
                  <img
                    src={file.file}
                    className="w-full h-full object-cover rounded-lg"
                    alt=""
                  />
                )}
                {file.file_type === "video" && (
                  <video
                    src={file.file}
                    className="w-full h-full object-cover rounded-lg"
                    controls
                    autoPlay={false}
                  >
                    <source src={file.file} type="video/mp4" />
                    <source src={file.file} type="video/webm" />
                    <source src={file.file} type="video/ogg" />
                  </video>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default ImageGallery;
