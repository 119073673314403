import React, { SyntheticEvent, useEffect, useState } from "react";
import { Collapse, Slider, TextField, Typography } from "@mui/material";
import { FiberManualRecord, KeyboardArrowDown } from "@mui/icons-material";
import { DisplayAmount } from "../../common/components/CurrencySwitch";

interface FilterTypeProps {
  fid: string;
  title: string;
  filterType: string;
  limitRange?: [number, number];
  limitPrefix?: string;
  limitSuffix?: string;
  currency?: string;
  isAmount?: boolean;
  limitUnit?: string;
  listOptions?: string[] | any[];
  defaultShow?: boolean;
  value?: string;
  doAction?: () => void;
  updateFilterList: (catg: string, value: string) => void;
  isStatesSelected?: boolean;
  collapse?: boolean;
  setIsActive?: (id: string) => void;
}
const FilterSubSection: React.FC<FilterTypeProps> = ({
  fid,
  title,
  filterType,
  limitRange = [0, 0],
  limitPrefix = "",
  limitSuffix = "",
  currency,
  isAmount = false,
  listOptions,
  defaultShow = false,
  value,
  doAction,
  updateFilterList,
  isStatesSelected,
  collapse,
  setIsActive,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [filterRange, setFilterRange] = useState<[number, number]>(limitRange);
  const [isApplied, setIsApplied] = useState(false);
  const [showBlock, setShowBlock] = useState(defaultShow);
  const [listTracker, setListTracker] = useState<any>({});

  useEffect(() => {
    if (fid === "state") {
      setIsApplied(!!isStatesSelected);
    }
  }, [fid, isStatesSelected]);

  useEffect(() => {
    if (collapse) {
      setShowBlock(false);
    }
  }, [collapse]);

  const priceChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    input: string
  ) => {
    const { value } = event.target;
    if (input === "min") {
      setFilterRange([parseInt(value), filterRange[1]]);
    } else {
      setFilterRange([filterRange[0], parseInt(value)]);
    }
    updateFilterList(fid, JSON.stringify(filterRange));
  };

  const handleSliderChange_ = (
    event: Event | SyntheticEvent<Element, Event>,
    newValue: number | number[]
  ) => {
    setIsApplied(true);
    setFilterRange(newValue as [number, number]);
  };

  const handleSliderChange = (
    event: Event | SyntheticEvent<Element, Event>,
    newValue: number | number[]
  ) => {
    updateFilterList(fid, JSON.stringify(newValue));
  };

  function valueLabelFormat(value: number) {
    return `${limitPrefix}${value?.toLocaleString()}${limitSuffix}`;
  }

  const getFilterBody = () => {
    switch (filterType) {
      case "price":
        return (
          <>
            <div
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <TextField
                onChange={(e) => priceChangeHandler(e, "min")}
                type="number"
                label="Min"
              />
              <TextField
                onChange={(e) => priceChangeHandler(e, "max")}
                type="number"
                label="Max"
              />
            </div>
          </>
        );

      case "slider":
        return (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p className="subheadings !text-primary">
                {isAmount ? (
                  <DisplayAmount amount={filterRange[0]} format="string" />
                ) : (
                  <>
                    {limitPrefix}
                    {filterRange[0]?.toLocaleString()}
                    {limitSuffix}
                  </>
                )}
              </p>{" "}
              -{" "}
              <p className="subheadings !text-primary">
                {isAmount ? (
                  <DisplayAmount amount={filterRange[1]} format="string" />
                ) : (
                  <>
                    {limitPrefix}
                    {filterRange[1]?.toLocaleString()}
                    {limitSuffix}
                  </>
                )}
              </p>
            </div>
            <div style={{ margin: "0 10px" }}>
              <Slider
                size="medium"
                min={limitRange[0]}
                max={limitRange[1]}
                value={filterRange}
                onChange={handleSliderChange_}
                onChangeCommitted={handleSliderChange}
                aria-label="Medium"
                valueLabelDisplay="auto"
                valueLabelFormat={valueLabelFormat}
              />
            </div>
            <div className="flex justify-between subheadings">
              <span className="text-primary/25">
                {isAmount ? (
                  <DisplayAmount amount={limitRange[0]} format="string" />
                ) : (
                  <>
                    {limitPrefix}
                    {limitRange[0]?.toLocaleString()}
                    {limitSuffix}
                  </>
                )}
              </span>
              <span className="text-primary/25">
                {isAmount ? (
                  <DisplayAmount amount={limitRange[1]} format="string" />
                ) : (
                  <>
                    {limitPrefix}
                    {limitRange[1]?.toLocaleString()}
                    {limitSuffix}
                  </>
                )}
              </span>
            </div>
          </>
        );

      case "checkbox":
        return (
          <div>
            <div className="flex flex-col gap-y-[10px]">
              {listOptions?.map((option, index) => (
                <div
                  key={`${title}-${
                    option?.id || option?.value || option
                  }-${index}`}
                  className="flex py-1 pl-[10px] items-center gap-x-[13px]"
                >
                  <input
                    type="checkbox"
                    id={`${fid}-${option?.id || option?.value || option}`}
                    onChange={() => {
                      const val = option?.id || option?.value || option;
                      const listTracker_ = { ...listTracker };

                      if (listTracker_[val]) {
                        delete listTracker_[val];
                      } else {
                        listTracker_[val] = 1;
                      }

                      setListTracker(listTracker_);
                      setIsApplied(!!Object.keys(listTracker_).length);
                      updateFilterList(
                        fid,
                        option?.id || option?.value || option
                      );
                    }}
                  />
                  <label
                    htmlFor={`${fid}-${option?.id || option?.value || option}`}
                    className="body-description"
                  >
                    {option?.name || option}
                  </label>
                </div>
              ))}
            </div>
          </div>
        );

      case "modal":
        return (
          <div
            onClick={doAction}
            className="flex justify-between pl-[15px] text-[14px] cursor-pointer"
          >
            <p className="">{value}</p>
            <img src="/assets/images/chevron-right.svg" alt="" />
          </div>
        );

      default:
        return null;
    }
  };

  const resetMe = () => {
    setListTracker({});
    setFilterRange(limitRange);
    setIsApplied(false);
    setShowBlock(false);
    updateFilterList(fid, "CLEAR");

    (
      document?.getElementById(`our-id-${fid}-${title}`) as HTMLFormElement
    )?.reset();
  };

  return (
    <div className="card-shadow rounded-lg p-[20px]">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={() => {
          if (!showBlock) {
            setIsActive && setIsActive(fid);
          }
          setShowBlock((showBlock) => !showBlock);
        }}
      >
        <p className="subheadings">
          {title}
          <span style={{ margin: "0 10px" }}>
            {isApplied && (
              <FiberManualRecord style={{ fontSize: 14, color: "green" }} />
            )}
          </span>
        </p>
        <KeyboardArrowDown
          style={{
            transform: `rotate(${showBlock ? 180 : 0}deg)`,
          }}
        />
      </div>

      <Collapse in={showBlock}>
        <form id={`our-id-${fid}-${title}`}>
          <div
            className="flex flex-col gap-y-[10px] body-description text-textBlack"
            style={{ marginTop: 20 }}
          >
            {getFilterBody()}
            <div
              className="flex mt-5 font-medium"
              style={{ justifyContent: "flex-end" }}
            >
              <span
                className="hover_underline"
                style={{ cursor: "pointer" }}
                onClick={resetMe}
              >
                Clear
              </span>
              {/* <span className="text-primary">Save</span> */}
            </div>
          </div>
        </form>
      </Collapse>
    </div>
  );
};

export default FilterSubSection;
