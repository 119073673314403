import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
// import {debounce} from 'lodash';

import HomeLayout, { Navbar } from "../../commonComponents/HomeLayout";
import FeaturedProperty from "../../components/FeaturedProperty";
import FilterSection from "../../components/FilterSection";
import StateModal from "../../modals/StateModal";
import MyGoogleMap from "../../components/GoogleMap";
import { AppDispatch, RootState } from "../../../../store/store";
import useInput from "../../../../hooks/useInput";
import { getListings } from "../../../../apis/listings/buyer";
import {
  getFeaturesList,
  getPropertyTypesList,
} from "../../../../apis/listings/general";
import { getStatesList } from "../../../../apis/locations";
import { sortOptions } from "../../../common/utils/constants";
import { Check } from "@mui/icons-material";
import { ListingInterface, ListingsInterface } from "../../../../types";
import {
  fetchListingsSuccess,
  setFilteredListings,
} from "../../../../store/slices/buyer/listingsSlice";
import { Pagination } from "@mui/material";

const Search = () => {
  const { searchText, sort } = useParams();
  const [searchParams] = useSearchParams();

  const [showFilter, setShowFilter] = useState(false);
  const [propertyTypesData, setPropertyTypesData] = useState<
    { name: string; value: string }[]
  >([]);
  const [featuresData, setFeaturesData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [searchQuery, setSearchQuery] = useState(searchText || "");
  const debouncedSearchTerm = useDebouncedValue(searchQuery, 1000);
  const [showStatesModal, setShowStatesModal] = useState(false);
  const [initFilter, setInitFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [storedSortKey, setStoredSortKey] = useState(
    searchParams.get("sort") || "Sort"
  );
  const [compiledFilters, setCompiledFilters] = useState<
    { group: string; catg: string; value: string | number }[]
  >([]);
  const filterRef = useRef<HTMLDivElement | null>(null);
  const sortRef = useRef<HTMLDivElement | null>(null);
  const toggleShowSort = () => setShowSort(!showSort);

  const dispatch: AppDispatch = useDispatch();
  const listings = useSelector((state: RootState) => state.listings);

  const selectListing = (listing: ListingsInterface | ListingInterface) => {
    const filteredList = [listing];
    dispatch(setFilteredListings(filteredList));
  };

  useEffect(() => {
    const init = () => {
      loadPropertyTypes();
      loadStatesAndPropertiesCounts();
      loadFeatures();
      dispatch(getListings());
    };

    init();
  }, []);

  useEffect(() => {
    doFilter({ searchQueryText: debouncedSearchTerm });
  }, [debouncedSearchTerm]);

  const loadPropertyTypes = async () => {
    const response = await getPropertyTypesList();

    if (response?.status) {
      setPropertyTypesData(response?.data);
    }
  };

  const LoadingState = () => (
    <div className="flex flex-col justify-center items-center h-[50vh]">
      <p className="mb-4 text-2xl">Loading...</p>
      <div className="payment-loader"></div>
    </div>
  );

  const loadStatesAndPropertiesCounts = async () => {
    const response = await getStatesList(true);

    if (response?.status) {
      setStatesData(response?.data);
    }
  };

  const loadFeatures = async () => {
    const response = await getFeaturesList();

    if (response?.status) {
      setFeaturesData(response?.data);
    }
  };

  const hideModals = (event: React.MouseEvent<HTMLDivElement>) => {
    if (showStatesModal) return;
    if (
      showFilter &&
      filterRef.current &&
      !filterRef.current.contains(event?.target as Node)
    ) {
      setShowFilter(false);
    }
    if (
      showSort &&
      sortRef.current &&
      !sortRef.current.contains(event?.target as Node)
    ) {
      setShowSort(false);
    }
  };

  const updateFilterList = (catg: string, value: string | number) => {
    let compiledFilters_ = [...compiledFilters];
    let doPush = false;

    //  if we clearing a section
    if (value === "CLEAR") {
      compiledFilters_ = compiledFilters?.filter(
        (filter) => filter.group !== catg
      );
    } else {
      const entry =
        catg === "p"
          ? [
              {
                group: catg,
                catg: "lp",
                value: JSON.parse(value.toString())[0],
              },
              {
                group: catg,
                catg: "hp",
                value: JSON.parse(value.toString())[1],
              },
            ]
          : catg === "pz"
          ? [
              {
                group: catg,
                catg: "lpz",
                value: JSON.parse(value.toString())[0],
              },
              {
                group: catg,
                catg: "hpz",
                value: JSON.parse(value.toString())[1],
              },
            ]
          : catg === "bn"
          ? [
              {
                group: catg,
                catg: "lbn",
                value: JSON.parse(value.toString())[0],
              },
              {
                group: catg,
                catg: "hbn",
                value: JSON.parse(value.toString())[1],
              },
            ]
          : catg === "bdn"
          ? [
              {
                group: catg,
                catg: "lbdn",
                value: JSON.parse(value.toString())[0],
              },
              {
                group: catg,
                catg: "hbdn",
                value: JSON.parse(value.toString())[1],
              },
            ]
          : [{ group: catg, catg, value }];

      if (["p", "pz", "bn", "bdn"].includes(catg)) {
        compiledFilters_ = compiledFilters_.filter(
          (filter) => filter.group !== catg
        );

        doPush = true;
      } else {
        //  check if catg/value already exists
        compiledFilters_ = compiledFilters?.filter(
          (filter) => filter.catg !== catg || filter.value !== value
        );

        doPush = compiledFilters_.length === compiledFilters.length;
      }

      //  if it doesn't exist, push into compilation
      if (doPush) {
        compiledFilters_?.push(...entry);
      }
    }

    setCompiledFilters(compiledFilters_);
  };

  const doFilter = async ({
    searchQueryText,
    sortOption,
  }: {
    searchQueryText?: string;
    sortOption?: string;
  }) => {
    let queryString = "?";

    const text =
      (typeof searchQueryText === "string" ? searchQueryText : "") ??
      (typeof searchQuery === "string" ? searchQuery : "");
    const searchString = text ? `search=${text}&` : "";

    queryString = `${queryString}${searchString}`;

    const sortParam = sortOption || storedSortKey;
    const sortString = sortParam ? `&sort_by=${sortParam}&` : "";

    queryString = `${queryString}${sortString}`;

    for (let filter of compiledFilters) {
      queryString = `${queryString}${filter.catg}=${filter.value}&`;
    }

    await dispatch(getListings(queryString));
  };

  const clearFilter = async () => {
    setCompiledFilters([]);
    setShowFilter(false);
    setInitFilter(false);

    await dispatch(getListings());
  };

  const doSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;

    setSearchQuery(text);
  };

  const doSort = (option: (typeof sortOptions)[0]) => {
    setStoredSortKey(option.title);
    doFilter({ sortOption: option.key });
    setShowSort(false);
  };

  const clearAll = () => {
    setSearchQuery("");
    clearFilter();
  };

  return (
    <div>
      <HomeLayout>
        <div className="sm:h-[100vh] flex flex-col" onClick={hideModals}>
          <div
            className="sm:flex items-center px-6 sm:px-8 lg:px-[30px] mt-[17px]"
            style={{
              justifyContent: "flex-start",
              gap: 30,
            }}
          >
            <div
              className="card-shadow relative h-[60px] max-w-full w-[430px] rounded-[50px]"
              style={{ boxShadow: "0px 1px 3px 1px #4682B426" }}
            >
              <input
                type="text"
                className="h-full px-[18.5px] w-full text-textBlack2 text-[14px] sm:text-[16px] x-search"
                style={{
                  backgroundColor: "transparent",
                  fontWeight: 300,
                  outline: "none",
                }}
                placeholder="Search property names"
                value={searchQuery}
                onChange={doSearch}
              />
              <div className="h-full flex items-center justify-center absolute top-0 right-5">
                <img src="/assets/images/search.svg" alt="" />
              </div>
            </div>
            <div className="flex gap-y-3 pt-6 sm:pt-0 flex-wrap sm:contents items-center">
              <div
                onClick={() => {
                  setInitFilter(true);
                  setShowFilter(!showFilter);
                }}
                className="flex items-center gap-x-2 ml-6 text-[16px] cursor-pointer"
              >
                <img
                  src="/assets/images/filter.svg"
                  className={`w-[18px] h-[18px] ${
                    !!compiledFilters.length ? "_green-img" : ""
                  }`}
                  alt=""
                />
                <span>Filter</span>
              </div>
              <span
                onClick={() => setShowMap(!showMap)}
                className="text-[16px] ml-8 sm:ml-0 cursor-pointer duration-200 hover:opacity-70"
              >
                {showMap ? "Hide Map" : "View Map"}
              </span>
              <span
                className="underline shrink-0 text-[16px] cursor-pointer throw-right"
                style={{ cursor: "pointer" }}
                onClick={clearAll}
              >
                Clear all
              </span>
              {/* <button
              className="filled-button w-full sm:w-fit"
              style={{ fontSize: 14 }}
            >
              SAVE SEARCH
            </button> */}
            </div>
          </div>
          <div className="flex-1 sm:overflow-y-hidden w-full sm:grid sm:grid-cols-7 gap-x-8 lg:gap-x-[50px] pt-[20px] px-6 sm:px-0 sm:pr-[50px]">
            <div
              className={`sm:col-span-3 w-full z-50 ${
                showMap ? "relative" : ""
              }`}
            >
              <div
                className={`${
                  showMap ? "h-[60vh]" : "h-fit"
                } sm:h-full w-full ${showMap ? "scale-x-100" : "scale-x-0"}`}
              >
                {!listings.loading && (
                  <MyGoogleMap
                    listings={listings.listings}
                    selectListing={selectListing}
                  />
                )}
              </div>
              <div
                className={`duration-500 w-[300px] sm:w-[350px] overflow-y-auto h-full bg-white fixed sm:fixed top-0 left-0 z-50 origin-left ${
                  showFilter ? "scale-x-100" : "scale-x-0"
                }`}
                ref={filterRef}
              >
                {(initFilter || showFilter) && (
                  <FilterSection
                    compiledFilters={compiledFilters}
                    showStateModal={() => setShowStatesModal(true)}
                    closeFilter={() => setShowFilter(false)}
                    propertyTypesList={propertyTypesData}
                    featuresList={featuresData}
                    updateFilterList={updateFilterList}
                    isStatesSelected={compiledFilters?.some(
                      (filter) => filter.group === "state"
                    )}
                    doFilter={doFilter}
                    clearFilter={clearFilter}
                    statesData={statesData}
                  />
                )}
              </div>
            </div>
            <div
              className={`${
                showMap
                  ? "col-span-4 mt-6 sm:mt-0"
                  : "col-span-7 mt-0 sm:pl-[50px]"
              } overflow-y-auto scrollbar-hidden`}
            >
              <h3 className="h3">Lands and Homes for Sale</h3>
              <div className="flex relative justify-between mt-[2px] mb-[30px] text-textBlack">
                <p className="subheadings">
                  {listings.listings.length} results
                </p>
                <button
                  onClick={toggleShowSort}
                  className="flex items-center gap-x-[9px] text-[16px]"
                >
                  {storedSortKey}
                  <img
                    className="w-[10px] h-[5px]"
                    src="/assets/images/chevron-down.svg"
                    alt=""
                  />
                </button>
                <div
                  className={`w-[260px] absolute z-20 origin-right duration-500 bg-white right-0 top-10 flex flex-col p-[10px] rounded-lg card-shadow ${
                    showSort ? "scale-x-100" : "scale-x-0"
                  }`}
                  ref={sortRef}
                >
                  {sortOptions.map((option) => (
                    <span
                      className="py-[10px] px-4 text-[14px]"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => doSort(option)}
                    >
                      {option.title}
                      {option.title === storedSortKey && (
                        <Check style={{ color: "green" }} />
                      )}
                    </span>
                  ))}
                </div>
              </div>
              <div
                className={`grid gap-10 ${
                  showMap
                    ? "lg:grid-cols-2"
                    : "sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-14"
                }`}
              >
                {listings.loading && <LoadingState />}
                {!listings.loading &&
                  listings.filteredListings.map((listing) => (
                    <FeaturedProperty key={listing.name} listing={listing} />
                  ))}
                {!listings.loading && listings.listings.length === 0 && (
                  <span className="body-description !text-red-700">
                    There are no listings yet
                  </span>
                )}
              </div>
              <div className="my-5 flex items-center justify-center">
                {!listings.loading && listings.listings.length > 0 && (
                  <Pagination
                    count={Math.ceil(listings.listings.length / 80)}
                  />
                )}
              </div>
            </div>
          </div>
          <StateModal
            open={showStatesModal}
            handleClose={() => setShowStatesModal(false)}
            statesList={statesData}
            updateStateFilterList={(id: number) =>
              updateFilterList("state", id)
            }
            selectedStates={compiledFilters.filter(
              (item) => item.catg === "state"
            )}
          />
        </div>
      </HomeLayout>
    </div>
  );
};

export default Search;

const useDebouncedValue = (inputValue: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
};
