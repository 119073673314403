import { BACKEND_URL } from "../../views/common/utils/constants";
import { getQueryStrings } from "../../views/common/utils/helpers";
import {
  IAPIResponse,
  IFilterFetch,
} from "../../views/common/utils/interfaces";
import { errorToastify } from "../../views/common/utils/Toasty";
import { CustomAxios } from "../customAxios";

export const getSellersList = async (filter?: IFilterFetch) => {
  try {
    const { searchQuery, filterQuery } = getQueryStrings(filter);

    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/admin/sellers?${searchQuery}${filterQuery}`
    );

    // const result = tempDummyList;

    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const getSellersDetails = async (inputData: any) => {
  const { id, url, slug } = inputData || {};

  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/admin/sellers/${slug}`
      // url
    );

    // const result = tempDummyDetail;

    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const postSellerApplicationAction = async ({
  slug,
  action,
  rejection_reason,
}: {
  slug: string;
  action: string;
  rejection_reason?: string;
}) => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().post(
      `${BACKEND_URL}/admin/sellers/${slug}/update-status`,
      {
        action,
        ...(action === "reject" ? { rejection_reason } : {}),
      }
    );
    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};
