import React, { ChangeEvent, MouseEvent, useMemo, useState } from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
// import DeleteIcon from "@mui/icons-material/Delete";
// import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { MenuItem, Select } from "@mui/material";
import CustomBadge from "../CustomBadge";
import CustomTablePagination from "./components/CustomTablePagination";
import { MoreHoriz } from "@mui/icons-material";

type Order = "asc" | "desc";

export interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  align?: "left" | "right" | "center" | "inherit" | "justify" | undefined;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: readonly HeadCell[];
  noSelections?: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    noSelections,
  } = props;
  const createSortHandler =
    (property: string) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {!noSelections && (
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all",
              }}
            />
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.align
                ? headCell.align
                : headCell.numeric
                ? "right"
                : "left"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
interface EnhancedTableToolbarProps {
  tableTitle?: { mainText: string; subText?: string };
  numSelected: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function CustomTable({
  tableType = "page-table",
  tableTitle,
  headCells,
  rows = [],
  hideChangePerPage = false,
  hidePagination = false,
  headerEndIcon = <></>,
  noToolbar = false,
  noSelections = false,
  tableBase = <></>,
  isLoading = false,
  mappedTableRows,
  additionalInfomation,
  onRowClick,
}: {
  tableType?: "page-table" | "section-table" | "card-table";
  tableTitle?: { mainText: string; subText?: string };
  headCells: readonly HeadCell[];
  rows: any[];
  hideChangePerPage?: boolean;
  hidePagination?: boolean;
  headerEndIcon?: JSX.Element;
  noToolbar?: boolean;
  noSelections?: boolean;
  tableBase?: JSX.Element;
  isLoading?: boolean;
  additionalInfomation?: JSX.Element | string;
  onRowClick?: (data: any) => void;
  mappedTableRows: ({
    row,
    labelId,
    isItemSelected,
  }: {
    row: any;
    labelId: string;
    isItemSelected: boolean;
  }) => JSX.Element;
}) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<any>();
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const { tableTitle, numSelected } = props;
    return (
      <Toolbar
        className="table-toolbar"
        sx={[
          {
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          },
          numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          },
        ]}
      >
        {tableTitle && (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h4"
            id="tableTitle"
            component="div"
            className="table-title"
          >
            <span>{tableTitle.mainText}</span>
            {numSelected > 0 ? (
              <span style={{ marginLeft: 10 }}>[ {numSelected} selected ]</span>
            ) : tableTitle.subText ? (
              <CustomBadge
                mode="success"
                nocase
                style={{ marginLeft: 13, gap: 5 }}
              >
                <>
                  {rows.length || 0}
                  <span
                    className={`${tableType === "page-table" ? "_pc" : ""}`}
                  >
                    {tableTitle.subText}
                  </span>
                </>
              </CustomBadge>
            ) : null}
          </Typography>
        )}
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>{/* <DeleteIcon /> */}</IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>{/* <FilterListIcon /> */}</IconButton>
          </Tooltip>
        )}
        {!hideChangePerPage && (
          <Select
            value={rowsPerPage}
            onChange={(e) =>
              handleChangeRowsPerPage(e as ChangeEvent<HTMLInputElement>)
            }
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={30}>40</MenuItem>
            <MenuItem value={30}>50</MenuItem>
            <MenuItem value={30}>100</MenuItem>
          </Select>
        )}
        {headerEndIcon && (
          <div className="_grey" style={{ marginRight: 10 }}>
            {headerEndIcon}
          </div>
        )}
      </Toolbar>
    );
  };

  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map(
        (n) => n.id || n.lid || n.slug || n.reference_id
      );
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      [...rows]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [rows, order, orderBy, page, rowsPerPage]
  );

  return (
    <Box className={tableType} sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {!noToolbar && (
          <EnhancedTableToolbar
            tableTitle={tableTitle}
            numSelected={selected.length}
          />
        )}
        {additionalInfomation && (
          <div className="_pc" style={{ padding: 15 }}>
            <Typography>{additionalInfomation}</Typography>
          </div>
        )}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              noSelections={noSelections}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = selected.includes(
                  row.id || row.lid || row.slug || row.reference_id
                );
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={
                      noSelections && onRowClick
                        ? () => onRowClick(row)
                        : (event) =>
                            handleClick(
                              event,
                              row.id || row.lid || row.slug || row.reference_id
                            )
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id || row.lid || row.slug || row.reference_id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    {mappedTableRows({ row, labelId, isItemSelected })}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        {isLoading && <div style={{ padding: 20 }}>L O A D I N G . . .</div>}
        {!hidePagination && (
          <CustomTablePagination
            handleChangePage={handleChangePage}
            totalPages={Math.ceil(rows?.length / rowsPerPage)}
            currentPageNumber={page}
          />
        )}
        {tableBase}
      </Paper>
    </Box>
  );
}
