import * as React from "react";
import moment from "moment";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import {
  IAdminTransaction,
  IAPIResponse,
  IFilterFetch,
  IListings,
} from "../../../../common/utils/interfaces";
import CustomTable, {
  HeadCell,
} from "../../../../common/components/CustomTable/CustomTable";
import CustomBadge from "../../../../common/components/CustomBadge";
import { getAdminBuyerTransactions } from "../../../../../apis/admin/purchases";
import { Link } from "react-router-dom";
import { getReceipt } from "../../../../../apis/listings/general";
import { downloadReceipt } from "../../../../common/utils/helpers";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";

type Props = {
  setDetailData: (arg: IListings) => void;
};

const headCells: readonly HeadCell[] = [
  {
    id: "created",
    numeric: false,
    disablePadding: true,
    label: "Transaction Date",
  },
  {
    id: "buyer_name",
    numeric: false,
    disablePadding: false,
    label: "Buyer Name",
  },
  {
    id: "payment_type_name",
    numeric: false,
    disablePadding: false,
    label: "Payment Type",
  },
  {
    id: "listing_name",
    numeric: false,
    disablePadding: false,
    label: "Property Name",
  },
  {
    id: "payment_status",
    numeric: false,
    disablePadding: false,
    label: "Payment Status",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
];

const TransactionsTable: React.FC<Props> = ({ setDetailData }: Props) => {
  const { layoutSearchText, layoutStatusFilter } = useSelector(
    (state: RootState) => state.search
  );

  const [tableData, setTableData] = React.useState<IAdminTransaction[]>([]);

  const tableTitle = { mainText: "My Transactions", subText: "transactions" };

  React.useEffect(() => {
    fetchTableData();
  }, [layoutSearchText, layoutStatusFilter]);

  const fetchTableData = async () => {
    const response: IAPIResponse | undefined = await getAdminBuyerTransactions({
      filter: layoutStatusFilter,
      search: layoutSearchText,
    });

    if (response?.status) {
      const { data, count } = response;

      //  convert structure to flatter (cos of listing name)
      const restructuredData = data.map((dataUnit: IAdminTransaction) => {
        dataUnit.listing_name = dataUnit.listing.name;
        return dataUnit;
      });
      setTableData(restructuredData);
    }
  };

  const getBadgeMode = (text: string) => {
    switch (text) {
      case "approved":
        return "success";

      case "rejected":
        return "error";

      default:
        return "info";
    }
  };

  const selectData = (
    evt: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    selectedData: IListings
  ) => {
    evt.stopPropagation();

    setDetailData(selectedData);
  };

  const MappedTableRows = ({
    row,
    labelId,
    isItemSelected,
  }: {
    row: IAdminTransaction;
    labelId: string;
    isItemSelected: boolean;
  }) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <div>
          <div>{moment(row.created).format("Do MMM, YYYY")}</div>
          <div
            style={{
              color: "#888",
              fontSize: 12,
            }}
          >
            {moment(row.created).format("hh:mm A")}
          </div>
        </div>
      </TableCell>
      <TableCell align="left">{row.buyer_name}</TableCell>
      <TableCell align="left">{row.payment_type_name}</TableCell>
      <TableCell align="left">{row.listing?.name}</TableCell>
      <TableCell align="left">
        <CustomBadge mode={getBadgeMode(row.payment_status)}>
          {row.payment_status}
        </CustomBadge>
      </TableCell>
      <TableCell align="center">
        {row.download_receipt_url ? (
          <Button
            variant="text"
            style={{
              padding: 4,
              minWidth: "unset",
              fontSize: 12,
              marginLeft: "auto",
              cursor: "pointer",
            }}
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              downloadReceipt(row.download_receipt_url);
            }}
          >
            Download receipt
          </Button>
        ) : (
          <span
            style={{
              cursor: "default",
              fontSize: 12,
              opacity: 0.4,
              textTransform: "uppercase",
            }}
          >
            Download receipt
          </span>
        )}
        {/* <MoreHoriz
          onClick={(evt) => {
            evt.stopPropagation();
            setAnchorEl(evt.currentTarget);
          }}
        /> */}
      </TableCell>
    </>
  );

  return (
    <CustomTable
      tableTitle={tableTitle}
      headCells={headCells}
      rows={tableData}
      // rows={testt}
      mappedTableRows={MappedTableRows}
    />
  );
};

export default TransactionsTable;
