import Marquee from "react-fast-marquee";
import CustomSpeedDial from "../../../Seller/components/CustomSpeedDial";
import HomeLayout from "../../commonComponents/HomeLayout";
import FeaturedProperty from "../../components/FeaturedProperty";
import mockData from "../../data.json";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { getListings } from "../../../../apis/listings/buyer";
import { Modal } from "@mui/material";

const Home = () => {
  const { features, popularCities } = mockData;
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [isUpgrading, setIsUpgrading] = useState(false);
  const setSortParams = (sortType: string) => {
    const sortParams = new URLSearchParams({
      sort: sortType,
    });
    return sortParams.toString();
  };
  const listings = useSelector((state: RootState) => state.listings);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      navigate(`/property-search/${searchText}`);
    }
  };

  useEffect(() => {
    dispatch(getListings());
  }, [dispatch]);

  useEffect(() => {
    setIsUpgrading(true);
  }, []);

  return (
    <HomeLayout>
      {isUpgrading && (
        <Modal open={isUpgrading} onClose={() => setIsUpgrading(false)}>
          <div className="modal__container">
            <img
              onClick={() => setIsUpgrading(false)}
              src="/assets/images/close-circle.svg"
              className="block ml-auto cursor-pointer mb-10"
              alt=""
            />
            <h2 className="modal__title !text-center">
              We are currently upgrading the website.
            </h2>

            <p className="text-2xl text-center mt-10">
              You may experience minor glitches in some functionalities.
            </p>
          </div>
        </Modal>
      )}
      <div className="h-[535px] px-4 flex flex-col justify-center text-center text-white relative">
        <Marquee
          style={{
            fontSize: 20,
            color: "yellow",
            marginBottom: 50,
          }}
        >
          * * The Home & Abroad platform will be officially launched soon. * *
        </Marquee>
        <img
          src="/assets/images/hero.png"
          className="absolute top-0 w-full h-full object-cover left-0 z-[-1]"
          alt=""
        />
        <h1 className="text-[28px] sm:text-[48px]">
          Your Nigerian Dream Home Awaits - <br /> Invest With Confidence
        </h1>
        <div className="flex items-center space-x-3 justify-center mt-[30px] mb-5 text-primary font-semibold">
          <Link to={`/property-search?${setSortParams("Popularity")}`}>
            <button className="bg-white w-28 px-[15px] py-[10px] rounded-lg">
              Hot
            </button>
          </Link>
          <Link to={`/property-search?${setSortParams("Newest Listings")}`}>
            <button className="bg-white px-[15px] py-[10px] rounded-lg">
              Recently Listed
            </button>
          </Link>
          <Link to="/property-search">
            <button className="bg-white px-[15px] py-[10px] rounded-lg">
              Just Sold
            </button>
          </Link>
        </div>
        <div className="rounded-[50px] relative flex justify-between bg-white w-full sm:w-[689px] mx-auto ">
          <input
            type="text"
            value={searchText}
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
            className="h-full w-full py-[18px] focus:outline-none px-6 rounded-[50px] sm:text-[16px] text-textGrey"
            placeholder="Address, City, or Neighborhood"
          />
          <div
            onClick={() => navigate(`/property-search/${searchText}`)}
            className="absolute cursor-pointer h-full right-6 top-0 flex items-center justify-center"
          >
            <img src="/assets/images/search.svg" alt="" />
          </div>
        </div>
      </div>
      <section className="lg:grid lg:grid-cols-2 gap-[50px] items-center px-4 sm:px-10 lg:px-[174px] pt-[30px]">
        <h3 className="text-[34px] shrink-0 text-textBlack font-[600]">
          Investing or Finding Your Dream Home? We Offer{" "}
          <span className="text-primary">Government-Verified </span>
          Properties.
        </h3>
        <div className="mt-6 sm:mt-0">
          <span className="text-[16px] block text-justify leading-[25px]">
            Looking to buy your dream property, find your next home or secure
            the perfect investment? Our platform connects you with the best
            opportunities in Nigeria’s real estate market. From modern
            apartments in bustling urban centers to serene homes in peaceful
            communities.
          </span>
          <div className="flex gap-x-6 mt-3">
            <Link to="/sign-up" className="mt-3 block">
              <button className="outline-button">SIGN UP </button>
            </Link>
            <Link to="/sign-in" className="mt-3 block">
              <button className="filled-button">SIGN IN </button>
            </Link>
          </div>
        </div>
      </section>
      <section className="grid gap-y-4 sm:grid-cols-2 relative gap-x-[50px] mt-[50px] mb-[30px] px-4 px-10 lg:px-[174px]">
        {/* <div className="fixed z-[10000] bottom-20 right-0 sm:right-16">
          <CustomSpeedDial />
        </div> */}
        <div className="flex gap-x-5 drop-shadow-cardShadow border rounded-[14px] p-5">
          <div className="h-[45px] shrink-0 w-[45px] rounded-full flex items-center justify-center">
            <img src="/assets/images/home.svg" alt="" />
          </div>
          <div>
            <h3 className="text-textBlack text-[24px] sm:text-[28px] mb-2">
              Find the best deals
            </h3>
            <span className="text-[14px] leading-[21px]">
              Browse exclusive offers on properties. Whether you’re looking to
              buy or invest, explore the best offers tailored just for you
            </span>
          </div>
        </div>
        <div className="flex gap-x-5 drop-shadow-cardShadow border rounded-[14px] p-5 bg-primary text-white">
          <div className="h-[45px] bg-white shrink-0 w-[45px] rounded-full flex items-center justify-center">
            <img src="/assets/images/document.svg" alt="" />
          </div>
          <div>
            <h3 className="text-[24px] sm:text-[28px] mb-2">
              Take action today!
            </h3>
            <span className="text-[14px] leading-[21px]">
              Found the perfect home? Submit your application quickly and
              easily, take the first step toward securing your dream property
            </span>
          </div>
        </div>
      </section>
      <section className="px-4 sm:px-10 lg:px-[174px]">
        <div className="overflow-x-hidden">
          <Marquee pauseOnHover pauseOnClick>
            <div className="flex gap-x-10 pr-10">
              {features.map(({ content, icon, title }) => (
                <div
                  key={title}
                  className="w-[360px] bg-primary p-[30px] rounded-[14px] text-white"
                >
                  <div className="h-[100px] flex items-center justify-center w-[102px] rounded-full bg-white">
                    <img src={icon} alt="" />
                  </div>
                  <h3 className="text-[28px] h-[84px] font-semibold my-[25px]">
                    {title}
                  </h3>
                  <p className="text-[16px]">{content}</p>
                </div>
              ))}
            </div>
          </Marquee>
        </div>
      </section>
      <section className="relative py-[58px] px-4 sm:px-10 lg:px-[175px] mt-[30px]">
        <img
          src="/assets/images/explore-bg.png"
          className="img-background"
          alt=""
        />
        <div className="lg:flex gap-x-[81px] items-center text-textWhite">
          <div className="flex-1">
            <h2 className="text-[36px] font-semibold">
              Your Next Home in Nigeria Awaits You, No Matter Where You Are
            </h2>
            <p className="mt-5 text-justify sm:text-left text-[16px]">
              Discover top properties and investment opportunities across
              Nigeria, tailored just for you. Whether you’re looking to buy, or
              invest, we've made it easy for you to find the perfect place - all
              from the comfort of your current location.
            </p>
          </div>
          <Link to="/property-search" className="mt-8 lg:mt-0 block">
            <button className="bg-white hover:bg-primary duration-300 mt-8 sm:mt-0 text-primary hover:text-textWhite px-[35px] py-3 text-[18px] uppercase font-semibold rounded-[10px]">
              Explore
            </button>
          </Link>
        </div>
      </section>
      <section className="px-4 sm:px-10 lg:px-[175px] py-[30px]">
        <h2 className="section__title">Featured Properties</h2>
        <div className="sm:flex justify-between items-center">
          <p className="font-medium text-[16px] sm:text-[22px]">
            Prime properties you don’t want to miss
          </p>
          {!listings.loading && listings.listings.length > 0 && (
            <Link to="/property-search">
              <button className="outline-button">BROWSE ALL</button>
            </Link>
          )}
        </div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-x-[35px] mt-5 gap-y-[33px]">
          {!listings.loading &&
            listings.listings.map((listing) => (
              <FeaturedProperty key={listing.name} listing={listing} />
            ))}
          {!listings.loading && listings.listings.length === 0 && (
            <span className="body-description !text-red-700">
              There are no listings yet
            </span>
          )}
        </div>
      </section>
      <section className="relative px-4 sm:px-10 lg:px-[175px] py-[30px] text-textWhite">
        <img src="/assets/images/hero.png" className="img-background" alt="" />
        <h2 className="section__title">Popular Cities</h2>
        <div className="sm:flex justify-between items-center">
          <p className="text-[22px] mt-2 sm:mt-0">
            Discover houses and apartments for sale in our most popular
            locations.
          </p>
          <Link to="/property-search" className="shrink-0">
            <button className="outline-button__load-more mt-8 sm:mt-0">
              Load More
            </button>
          </Link>
        </div>
        <div className="overflow-x-hidden whitespace-nowrap">
          <Marquee autoFill pauseOnClick pauseOnHover>
            {popularCities.map(({ image, name, comingSoon }) => (
              <div key={name} className="city-container">
                <div className="relative">
                  <img
                    src={image}
                    className="mt-[25px] h-[298px] rounded-[14px] w-[343px] object-cover mb-[18px]"
                    alt=""
                  />
                  {comingSoon && (
                    <div className="img-overlay">
                      <p className="text-textWhite text-[36px] leading-[44px] font-bold text-center">
                        Coming <br /> Soon
                      </p>
                    </div>
                  )}
                </div>
                <h2 className="city-container__name">{name}</h2>
              </div>
            ))}
          </Marquee>
        </div>
      </section>
    </HomeLayout>
  );
};

export default Home;
