import { useLocation, useNavigate } from "react-router-dom";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Add, ArrowBackIos, Close, Person } from "@mui/icons-material";
import { NumericFormat } from "react-number-format";

import SellerDashboardLayout from "../../commonComponents/DashboardLayout";
import {
  getFeaturesList,
  getPropertyTypeSubClassificationsList,
} from "../../../../apis/listings/general";
import CustomUpload from "./components/CustomUpload";
import UploadedItem from "./components/UploadedItem";
import {
  categorizeFeatures,
  convertInputDataToFormData,
  unslugText,
} from "../../../common/utils/helpers";
import {
  getMyListingDetails,
  postNewListing,
  updateListing,
} from "../../../../apis/listings/sellers";
import {
  IAPIResponse,
  IPropertyFeatures,
} from "../../../common/utils/interfaces";
import Loading from "../../../common/components/Loading";
import {
  getLGAsList,
  getStatesList,
  getTownsList,
} from "../../../../apis/locations";
import { errorToastify, successToastify } from "../../../common/utils/Toasty";
import SuccessModal from "../../../common/components/SuccessModal";
import {
  APP_CURRENCIES,
  APP_DEFAULT_CURRENCY,
  documentsTypes,
  MAX_FILE_SIZE,
} from "../../../common/utils/constants";
import moment from "moment";

const INITIAL_UPLOAD_ICONS = 4;

const ListProperty: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const uploadImagesRef: any = React.useRef();
  const uploadVideosRef: any = React.useRef();

  const steps = 3;
  const [step, setStep] = useState(1);
  const [listingId, setListingId] = useState("");
  const [pageMode, setPageMode] = useState("create");
  const [loadedFeatures, setLoadedFeatures] = useState<any>({});
  const [states, setStates] = useState<
    { id: number; name: string; code: string }[]
  >([]);
  const [lgas, setLgas] = useState<{ id: number; name: string }[]>([]);
  const [isLoadingLgas, setIsLoadingLgas] = useState(false);
  const [towns, setTowns] = useState<{ id: number; name: string }[]>([]);
  const [stateKey, setStateKey] = useState("randomState");
  const [lgaKey, setLgaKey] = useState("randomLga");
  const [townKey, setTownKey] = useState("randomTown");
  const [propertyTypes, setPropertyTypes] = useState<
    {
      id: number;
      name: string;
      property_type: string;
      type_classification: string;
    }[]
  >([]);
  const [pTypeKey, setPTypeKey] = useState("random");
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [videoFiles, setVideoFiles] = useState<File[]>([]);
  const [docFiles, setDocFiles] = useState<any>({});
  const [featuresCheckboxData, setFeaturesCheckboxData] = useState<any[]>([]);
  const [inputData, setInputData] = useState<any>({});
  const [preUpdateData, setPreUpdateData] = useState<any>({});
  const [updateData, setUpdateData] = useState<any>({});
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [isLoadingDetailsError, setIsLoadingDetailsError] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [useGoogleMapsUrl, setUseGoogleMapsUrl] = useState(false);
  const [videoLinksList, setVideoLinksList] = useState<string[]>([""]);
  const [embedLinksList, setEmbedLinksList] = useState<string[]>([""]);

  const [isBusy, setIsBusy] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState<string[]>([]);
  const [errorFields, setErrorFields] = useState<string[]>([]);

  useEffect(() => {
    if (location.state) {
      const listingId = location.state.listingId;
      const listingUrl = location.state.listingUrl;
      if (listingId) {
        setListingId(listingId);
        setPageMode("edit");
        loadPropertyDetails({ listingId });
      } else if (listingUrl) {
        setListingId(listingUrl);
        setPageMode("edit");
        loadPropertyDetails({ listingUrl });
      }
    }
  }, [location]);

  useEffect(() => {
    const initPage = async () => {
      loadFeatures();
      loadStates();
      loadPropertyTypeSubClassifications();
    };

    initPage();
  }, []);

  useEffect(() => {
    if (Object.keys(preUpdateData).length && states.length) {
      const preUpdateData_ = { ...preUpdateData };
      const returnedState = preUpdateData_.state;
      const stateId = states.find((state) => state.name === returnedState)?.id;

      preUpdateData_.state = stateId;
      setUpdateData(preUpdateData_);

      loadLgas(stateId);
      loadTowns(stateId);
      setStateKey(Math.random().toString());
    }
  }, [preUpdateData, states]);

  useEffect(() => {
    if (Object.keys(preUpdateData).length && lgas.length) {
      const preUpdateData_ = { ...preUpdateData };

      const returnedTown = preUpdateData_.lga;
      const lgaId = lgas.find((lga) => lga.name === returnedTown)?.id;
      preUpdateData_.lga = lgaId;

      setUpdateData(preUpdateData_);
      setLgaKey(Math.random().toString());
      setTownKey(Math.random().toString());
    }
  }, [preUpdateData, lgas]);

  useEffect(() => {
    if (inputData?.state) {
      loadLgas(inputData?.state);
      loadTowns(inputData?.state);
    }
  }, [inputData?.state, updateData?.state]);

  const loadFeatures = async () => {
    setIsBusy(true);
    const result = await getFeaturesList();
    setIsBusy(false);

    if (result?.status) {
      const data: IPropertyFeatures[] = result.data;
      const categorized: any = categorizeFeatures(data);

      setLoadedFeatures(categorized);
    }
  };
  const loadLgas = async (stateValue?: number) => {
    if (!stateValue) return;

    setIsBusy(true);
    setIsLoadingLgas(true);
    const result = await getLGAsList(stateValue);
    setIsBusy(false);
    setIsLoadingLgas(false);

    if (result?.status) {
      const data = result.data;
      setLgas(data);
    }
  };
  const loadStates = async () => {
    setIsBusy(true);
    const result = await getStatesList();
    setIsBusy(false);

    if (result?.status) {
      const data = result.data;
      setStates(data);
    }
  };
  const loadTowns = async (stateValue?: number) => {
    if (!stateValue) return;

    setIsBusy(true);
    const result = await getTownsList(stateValue);
    setIsBusy(false);

    if (result?.status) {
      const data = result.data;
      setTowns(data);
    }
  };
  /* const loadPropertyTypes = async () => {
    setIsBusy(true);
    const result = await getPropertyTypesList();
    setIsBusy(false);

    if (result?.status) {
      const data = result.data;
      setPropertyTypes(data);
    }
  };
  const loadPropertyTypeClassifications = async () => {
    setIsBusy(true);
    const result = await getPropertyTypeClassificationsList();
    setIsBusy(false);

    if (result?.status) {
      const data = result.data;
      setPropertyTypes(data);
    }
  }; */
  const loadPropertyTypeSubClassifications = async () => {
    setIsBusy(true);
    const result = await getPropertyTypeSubClassificationsList();
    setIsBusy(false);

    if (result?.status) {
      const data = result.data;
      setPropertyTypes(data);
      setPTypeKey(Math.random().toString());
    }
  };

  const loadPropertyDetails = async ({
    listingId,
    listingUrl,
  }: {
    listingId?: string;
    listingUrl?: string;
  }) => {
    setIsLoadingDetails(true);
    setIsBusy(true);
    const response: IAPIResponse | undefined = await getMyListingDetails({
      id: listingId,
      url: listingUrl,
    });
    setIsBusy(false);
    setIsLoadingDetails(false);

    if (response?.status) {
      const fetchedData = response.data;

      if (fetchedData.features?.length) {
        setUpdateData({
          ...fetchedData,
          features: fetchedData.features.map((feature: any) => feature?.id),
        });
      }
      if (fetchedData.video_links?.length) {
        setVideoLinksList(fetchedData?.video_links);
      }
      if (fetchedData.virtual_tour_embed_link?.length) {
        setEmbedLinksList(fetchedData.virtual_tour_embed_link);
      }
      setPreUpdateData(response.data);

      setUseGoogleMapsUrl(!!response.data?.map_url);
    } else {
      setIsLoadingDetailsError(true);
    }
  };

  const updateFormData = (evt: ChangeEvent<HTMLInputElement> | any) => {
    const existingData = { ...inputData };

    updateErrorUI(evt.target.name);
    const fixedSelected = {
      default_currency: APP_DEFAULT_CURRENCY,
    };

    if (["state"].includes(evt.target.name)) {
      setLgaKey(Math.random().toString());
      setTownKey(Math.random().toString());
    }

    if (["property_type", "type_classification"].includes(evt.target.name)) {
      setPTypeKey(Math.random().toString());
      existingData.sub_classification = "";
    }

    const newFormData = {
      ...existingData,
      ...fixedSelected,
      [evt.target.name]: evt.target.value,
    };
    setInputData(newFormData);
  };

  const updateCheckboxes = (evt: ChangeEvent<HTMLInputElement>) => {
    const isChecked = evt.target.checked;
    const checkValue = Number(evt.target.value);

    let checkboxData = [...featuresCheckboxData];
    if (isChecked) {
      checkboxData.push(checkValue);
    } else {
      checkboxData = checkboxData.filter((item: number) => item !== checkValue);
    }

    setFeaturesCheckboxData(checkboxData);
    updateFormData({ target: { name: "features", value: checkboxData } });
  };

  const updateErrorUI = (inputField: string) => {
    setErrorMsgs([]);

    const errorFields_ = [...errorFields];
    const newErrorFields_ = errorFields_.filter(
      (field) => field !== inputField
    );
    setErrorFields(newErrorFields_);
  };

  const handleErrorResponses = (response: any) => {
    if (response?.message === "There is an error with one or more fields") {
      const fieldsArray1: string[] = Object.keys(response.errors || {});
      const fieldsArray2: string[] = Object.keys(response.data || {});
      const fields = [...fieldsArray1, ...fieldsArray2].join(", ");

      setErrorMsgs([`${response.message}: ${fields}`]);
      setErrorFields([...fieldsArray1, ...fieldsArray2]);
    } else {
      setErrorMsgs(
        response?.errors
          ? Array.isArray(response?.errors)
            ? response?.errors.map((error: any) =>
                typeof error === "string" ? error : JSON.stringify(error)
              )
            : [
                typeof response?.errors === "string"
                  ? response?.errors
                  : JSON.stringify(response?.errors),
              ]
          : [
              response?.message ||
                "An error occurred. Check your connection and try again.",
            ]
      );
    }
  };

  const doSubmitData = async () => {
    const finalData = convertInputDataToFormData(
      inputData,
      imageFiles,
      videoFiles,
      docFiles,
      videoLinksList,
      embedLinksList
    );

    setIsBusy(true);
    let response: IAPIResponse | undefined;

    if (listingId) {
      response = await updateListing(
        listingId,
        finalData,
        pageMode === "create",
        step
      );
    } else {
      response = await postNewListing(finalData);
    }

    if (response?.status === true) {
      setListingId(response.data?.lid || listingId || "");
      setUpdateData(response.data);

      if (step < steps) {
        setStep((currentStep) => currentStep + 1);
        window.scrollTo(0, 0);
      } else {
        setShowSuccessModal(true);

        successToastify("Listing added successfully");
        setImageFiles([]);
        setVideoFiles([]);
        setDocFiles({});
        setFeaturesCheckboxData([]);

        setTimeout(() => {
          setShowSuccessModal(false);
          setInputData({});
          setUpdateData({});

          navigate("/seller/dashboard");
        }, 5000);
      }
    } else {
      // errorToastify("An error occurred");
      handleErrorResponses(response);
    }
    setIsBusy(false);
  };

  const validateFiles = (selectedFiles: any) => {
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles: File[] = Array.from(selectedFiles);
      const validFiles = newFiles.filter(
        (file: File) => file.size <= MAX_FILE_SIZE * 1024 * 1024
      );

      if (newFiles.length > validFiles.length) {
        errorToastify(
          `${
            newFiles.length - validFiles.length
          } file(s) above size limit (${MAX_FILE_SIZE}MB). Skipped.`
        );
      }

      return validFiles;
    }
    return null;
  };

  const handleFileImageChange = (event: any) => {
    updateErrorUI("uploads");

    const selectedFiles = event.target.files;
    const validFiles = validateFiles(selectedFiles);

    validFiles &&
      setImageFiles((prevFiles: any) => [...prevFiles, ...validFiles]);
  };

  const handleFileVideoChange = (event: any) => {
    updateErrorUI("uploads");

    const selectedFiles = event.target.files;
    const validFiles = validateFiles(selectedFiles);

    validFiles &&
      setVideoFiles((prevFiles: any) => [...prevFiles, ...validFiles]);
  };

  const updateDocuments = (fileId: string, uploadedFiles: File[]) => {
    updateErrorUI(fileId);

    const currentDocFiles = { ...docFiles, [fileId]: uploadedFiles };
    setDocFiles(currentDocFiles);
  };

  const deleteItem = (type: "image" | "video", index: number) => {
    let currentFiles;

    if (type === "image") {
      currentFiles = [...imageFiles];
      currentFiles.splice(index, 1);
      setImageFiles(currentFiles);
    } else {
      currentFiles = [...videoFiles];
      currentFiles.splice(index, 1);
      setVideoFiles(currentFiles);
    }
  };

  const clearErrorBanner = () => {
    setErrorMsgs([]);
  };

  const decreasingYearsList = () => {
    const thisYear = moment().format("YYYY");
    const yearsList = new Array(100)
      .fill(null)
      .map((unit, index) => Number(thisYear) - index);
    return yearsList;
  };

  const updateLink = (
    type: "video" | "embed",
    action: "add" | "remove" | "edit",
    index = 0,
    evt?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let state, setState;

    if (type === "video") {
      state = [...videoLinksList];
      setState = setVideoLinksList;
    } else {
      state = [...embedLinksList];
      setState = setEmbedLinksList;
    }

    if (action === "add") {
      state.push("");
    } else if (action === "remove") {
      state.splice(index, 1);
    } else {
      state[index] = evt?.target?.value || "";
    }

    setState(state);
  };

  return (
    <SellerDashboardLayout noPadding>
      <>
        <div className="page-central">
          <div className="page-title">
            <ArrowBackIos
              style={{ fontSize: 28, cursor: "pointer" }}
              onClick={() => {
                if (step <= 1) {
                  navigate("/seller/dashboard");
                } else {
                  setStep((currentStep) => currentStep - 1);
                }
              }}
            />
            <Typography variant="h4" style={{ fontSize: 18 }}>
              {listingId ? "Edit" : "List"} Property
            </Typography>
            <div className="_avatar">
              <Person style={{ fontSize: 65 }} />
            </div>
          </div>

          {!listingId || !!updateData?.name ? (
            <>
              <div style={{ display: step === 1 ? "block" : "none" }}>
                <div className="page-header">
                  <Typography variant="h4">
                    Please fill property details appropriately in the form below
                  </Typography>
                </div>
                <div className="page-body">
                  <div className="form-form">
                    <div className="form-row">
                      <div
                        className={`form-input ${
                          errorFields.includes("name") ? "_error" : ""
                        }`}
                      >
                        <Typography>Property label/caption *</Typography>
                        <TextField
                          name="name"
                          defaultValue={updateData?.name || ""}
                          onChange={updateFormData}
                          fullWidth
                          placeholder="Property name"
                        />
                      </div>
                      <div
                        className={`form-input ${
                          errorFields.includes("property_type") ? "_error" : ""
                        }`}
                      >
                        <Typography>Property type *</Typography>
                        <Select
                          name="property_type"
                          defaultValue={updateData?.property_type || "select"}
                          onChange={updateFormData}
                          fullWidth
                        >
                          <MenuItem value="select">Select one</MenuItem>
                          {propertyTypes
                            ?.map((propertyType) => propertyType.property_type)
                            ?.reduce(
                              (finalArray: string[], propertyType: string) => {
                                if (!finalArray.includes(propertyType)) {
                                  finalArray.push(propertyType);
                                }
                                return finalArray;
                              },
                              []
                            )
                            ?.map((propertyType) => (
                              <MenuItem key={propertyType} value={propertyType}>
                                <span style={{ textTransform: "capitalize" }}>
                                  {propertyType}
                                </span>
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div
                        className={`form-input ${
                          errorFields.includes("type_classification")
                            ? "_error"
                            : ""
                        }`}
                      >
                        <Typography>Property classification *</Typography>
                        <Select
                          name="type_classification"
                          defaultValue={
                            updateData?.type_classification || "select"
                          }
                          onChange={updateFormData}
                          disabled={
                            !inputData.property_type &&
                            !updateData.property_type
                          }
                          fullWidth
                        >
                          <MenuItem value="select">Select one</MenuItem>
                          {propertyTypes
                            .filter(
                              (propertyType) =>
                                propertyType.property_type ===
                                (inputData.property_type ||
                                  updateData.property_type)
                            )
                            ?.reduce((finalArray: string[], propertyType) => {
                              if (
                                !finalArray.includes(
                                  propertyType.type_classification
                                )
                              ) {
                                finalArray.push(
                                  propertyType.type_classification
                                );
                              }
                              return finalArray;
                            }, [])
                            ?.map((propertyTypeClassification) => (
                              <MenuItem
                                key={propertyTypeClassification}
                                value={propertyTypeClassification}
                              >
                                <span style={{ textTransform: "capitalize" }}>
                                  {unslugText(propertyTypeClassification)}
                                </span>
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                      <div
                        className={`form-input ${
                          errorFields.includes("sub_classification")
                            ? "_error"
                            : ""
                        }`}
                      >
                        <Typography>Sub-classification *</Typography>
                        <Select
                          key={pTypeKey}
                          name="sub_classification"
                          defaultValue={
                            updateData?.sub_classification?.id || "select"
                          }
                          onChange={updateFormData}
                          fullWidth
                          disabled={
                            !inputData.type_classification &&
                            !updateData.type_classification
                          }
                        >
                          <MenuItem value="select">Select one</MenuItem>
                          {propertyTypes
                            ?.filter(
                              (propertyType) =>
                                propertyType.property_type ===
                                  (inputData.property_type ||
                                    updateData.property_type) &&
                                propertyType.type_classification ===
                                  (inputData.type_classification ||
                                    updateData.type_classification)
                            )
                            ?.map((propertyType) => (
                              <MenuItem
                                key={`${propertyType.id}-${propertyType.name}`}
                                value={propertyType.id}
                              >
                                {propertyType.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div
                        className={`form-input ${
                          errorFields.includes("address") ? "_error" : ""
                        }`}
                      >
                        <Typography>Address *</Typography>
                        <TextField
                          name="address"
                          defaultValue={updateData?.address || ""}
                          onChange={updateFormData}
                          fullWidth
                          placeholder="Address *"
                        />
                      </div>
                      <div
                        className={`form-input ${
                          errorFields.includes("state") ? "_error" : ""
                        }`}
                      >
                        <TextField
                          key={`${stateKey}`}
                          name="state"
                          label="State"
                          select
                          defaultValue={updateData?.state || "select"}
                          onChange={updateFormData}
                          fullWidth
                        >
                          <MenuItem value="select">Select state *</MenuItem>
                          {states?.map((state) => (
                            <MenuItem key={state.id} value={state.id}>
                              {state.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div className="form-row">
                      <div
                        className={`form-input ${
                          errorFields.includes("lga") ? "_error" : ""
                        }`}
                      >
                        <TextField
                          key={`${lgaKey}`}
                          name="lga"
                          label="Locality"
                          select
                          defaultValue={updateData?.lga || "select"}
                          onChange={updateFormData}
                          fullWidth
                          disabled={
                            (!inputData?.state && !updateData?.state) ||
                            isLoadingLgas
                          }
                        >
                          <MenuItem value="select">
                            Select locality *
                            {isLoadingLgas
                              ? " (loading...)"
                              : inputData?.state
                              ? ""
                              : " (First, select State)"}
                          </MenuItem>
                          {lgas?.map((lga) => (
                            <MenuItem key={lga.id} value={lga.id}>
                              {lga.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                      <div
                        className={`form-input ${
                          errorFields.includes("city") ? "_error" : ""
                        }`}
                      >
                        <TextField
                          key={`${townKey}`}
                          name="city"
                          select
                          defaultValue={
                            towns?.find(
                              (town) => town.name === updateData?.city
                            )?.id || "select"
                          }
                          label="Area"
                          onChange={updateFormData}
                          fullWidth
                          disabled={
                            (!inputData?.state && !updateData.state) ||
                            isLoadingLgas
                          }
                        >
                          <MenuItem value="select" disabled>
                            Select area *
                            {isLoadingLgas
                              ? " (loading...)"
                              : inputData?.city
                              ? ""
                              : " (First, select State)"}
                          </MenuItem>
                          {towns?.map((town) => (
                            <MenuItem key={town.id} value={town.id}>
                              {town.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-input">
                        <Typography>
                          {useGoogleMapsUrl
                            ? "Google Maps URL"
                            : "Geo-coordinates"}{" "}
                          <span
                            style={{
                              cursor: "pointer",
                              opacity: 0.5,
                              backgroundColor: "#e8e8e8",
                              padding: "3px 10px",
                              fontSize: "0.8em",
                              color: "#045004",
                              borderRadius: 10,
                              boxShadow: "1px 1px 2px #a3c2a3",
                              marginLeft: 10,
                            }}
                            onClick={() =>
                              setUseGoogleMapsUrl((value) => !value)
                            }
                          >
                            {" "}
                            {useGoogleMapsUrl
                              ? "Use Geo-coordinates"
                              : "Use Google Maps URL"}
                          </span>
                        </Typography>
                        {useGoogleMapsUrl ? (
                          <TextField
                            name="map_url"
                            defaultValue={updateData?.map_url || ""}
                            onChange={updateFormData}
                            fullWidth
                            placeholder="Enter URL"
                          />
                        ) : (
                          <TextField
                            name="latitude"
                            defaultValue={updateData?.latitude || ""}
                            onChange={updateFormData}
                            fullWidth
                            placeholder="Latitude"
                            label="Latitude"
                          />
                        )}
                      </div>
                      {!useGoogleMapsUrl && (
                        <div
                          className={`form-input ${
                            errorFields.includes("") ? "_error" : ""
                          }`}
                        >
                          {/* <Typography className="_not-visible">
                  Geo-coordinates
                </Typography> */}
                          <TextField
                            name="longitude"
                            defaultValue={updateData?.longitude || ""}
                            onChange={updateFormData}
                            fullWidth
                            placeholder="Longitude"
                            label="Longitude"
                          />
                        </div>
                      )}
                    </div>
                    <div className="form-row">
                      <div
                        className={`form-input ${
                          errorFields.includes("price") ? "_error" : ""
                        }`}
                      >
                        <Typography>Price *</Typography>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl css-1q3t5gr-MuiInputBase-root-MuiOutlinedInput-root">
                          <NumericFormat
                            name="price"
                            defaultValue={updateData?.price || ""}
                            // onChange={updateFormData}
                            onValueChange={(values) => {
                              const { formattedValue, value, floatValue } =
                                values;
                              updateFormData({
                                target: { name: "price", value },
                              });
                              // do something with floatValue
                            }}
                            placeholder="Price"
                            thousandSeparator
                            valueIsNumericString
                            allowNegative={false}
                            className="_input MuiInputBase-input MuiOutlinedInput-input css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input"
                          />
                        </div>
                        {/* <TextField
                          type="number"
                          inputProps={{ min: 0 }}
                        /> */}
                      </div>
                      <div
                        className={`form-input ${
                          errorFields.includes("default_currency")
                            ? "_error"
                            : ""
                        }`}
                      >
                        <Typography>Currency *</Typography>
                        <TextField
                          name="default_currency"
                          defaultValue={
                            updateData?.default_currency || APP_CURRENCIES.NGN
                          }
                          onChange={updateFormData}
                          fullWidth
                          disabled
                        />
                        {/* <Select
                          name="default_currency"
                          defaultValue={
                            updateData?.default_currency || APP_CURRENCIES.NGN
                          }
                          onChange={updateFormData}
                          fullWidth
                          disabled
                        >
                          <MenuItem value="select">Select one</MenuItem>
                          <MenuItem value={APP_CURRENCIES.NGN} selected>
                            NGN
                          </MenuItem>
                        </Select> */}
                      </div>
                    </div>
                    <div className="form-row">
                      <div
                        className={`form-input ${
                          errorFields.includes("square_footage") ? "_error" : ""
                        }`}
                      >
                        <Typography>Square meter *</Typography>
                        {/* <TextField
                          name="square_footage"
                          defaultValue={updateData?.square_footage || ""}
                          onChange={updateFormData}
                          fullWidth
                          placeholder="Square Meter"
                          type="number"
                        /> */}
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl css-1q3t5gr-MuiInputBase-root-MuiOutlinedInput-root">
                          <NumericFormat
                            name="square_footage"
                            defaultValue={updateData?.square_footage || ""}
                            // onChange={updateFormData}
                            onValueChange={(values) => {
                              const { formattedValue, value, floatValue } =
                                values;
                              updateFormData({
                                target: { name: "square_footage", value },
                              });
                              // do something with floatValue
                            }}
                            placeholder="Square Meter"
                            thousandSeparator
                            valueIsNumericString
                            allowNegative={false}
                            className="_input MuiInputBase-input MuiOutlinedInput-input css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input"
                          />
                        </div>
                      </div>
                      <div
                        className={`form-input ${
                          errorFields.includes("") ? "_error" : ""
                        }`}
                      >
                        <Typography>Price per sqm</Typography>
                        {/* <TextField
                          name="price_per_square_meter"
                          defaultValue={
                            updateData?.price_per_square_meter || ""
                          }
                          onChange={updateFormData}
                          fullWidth
                          placeholder="Price per sqm"
                          type="number"
                        /> */}
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl css-1q3t5gr-MuiInputBase-root-MuiOutlinedInput-root">
                          <NumericFormat
                            name="price_per_square_meter"
                            defaultValue={
                              updateData?.price_per_square_meter || ""
                            }
                            // onChange={updateFormData}
                            onValueChange={(values) => {
                              const { formattedValue, value, floatValue } =
                                values;
                              updateFormData({
                                target: {
                                  name: "price_per_square_meter",
                                  value,
                                },
                              });
                              // do something with floatValue
                            }}
                            placeholder="Price per sqm"
                            thousandSeparator
                            valueIsNumericString
                            allowNegative={false}
                            className="_input MuiInputBase-input MuiOutlinedInput-input css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div
                        className={`form-input ${
                          errorFields.includes("bedrooms") ? "_error" : ""
                        }`}
                      >
                        <Typography>Bedrooms</Typography>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl css-1q3t5gr-MuiInputBase-root-MuiOutlinedInput-root">
                          <NumericFormat
                            name="bedrooms"
                            defaultValue={updateData?.bedrooms || ""}
                            // onChange={updateFormData}
                            onValueChange={(values) => {
                              const { formattedValue, value, floatValue } =
                                values;
                              updateFormData({
                                target: {
                                  name: "bedrooms",
                                  value,
                                },
                              });
                              // do something with floatValue
                            }}
                            placeholder="Bedrooms"
                            thousandSeparator
                            valueIsNumericString
                            allowNegative={false}
                            className="_input MuiInputBase-input MuiOutlinedInput-input css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input"
                          />
                        </div>
                      </div>
                      <div
                        className={`form-input ${
                          errorFields.includes("bathrooms") ? "_error" : ""
                        }`}
                      >
                        <Typography>Bathrooms</Typography>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl css-1q3t5gr-MuiInputBase-root-MuiOutlinedInput-root">
                          <NumericFormat
                            name="bathrooms"
                            defaultValue={updateData?.bathrooms || ""}
                            // onChange={updateFormData}
                            onValueChange={(values) => {
                              const { formattedValue, value, floatValue } =
                                values;
                              updateFormData({
                                target: {
                                  name: "bathrooms",
                                  value,
                                },
                              });
                              // do something with floatValue
                            }}
                            placeholder="Bathrooms"
                            thousandSeparator
                            valueIsNumericString
                            allowNegative={false}
                            className="_input MuiInputBase-input MuiOutlinedInput-input css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div
                        className={`form-input ${
                          errorFields.includes("bathrooms") ? "_error" : ""
                        }`}
                      >
                        <Typography>Toilets</Typography>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl css-1q3t5gr-MuiInputBase-root-MuiOutlinedInput-root">
                          <NumericFormat
                            name="toilets"
                            defaultValue={updateData?.toilets || ""}
                            // onChange={updateFormData}
                            onValueChange={(values) => {
                              const { formattedValue, value, floatValue } =
                                values;
                              updateFormData({
                                target: {
                                  name: "toilets",
                                  value,
                                },
                              });
                              // do something with floatValue
                            }}
                            placeholder="Toilets"
                            thousandSeparator
                            valueIsNumericString
                            allowNegative={false}
                            className="_input MuiInputBase-input MuiOutlinedInput-input css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input"
                          />
                        </div>
                      </div>
                      <div
                        className={`form-input ${
                          errorFields.includes("year_built") ? "_error" : ""
                        }`}
                        style={{ visibility: "hidden" }}
                      >
                        <Typography>Year built *</Typography>
                        <Select
                          name="year_built"
                          defaultValue={updateData?.year_built || "select"}
                          onChange={updateFormData}
                          fullWidth
                        >
                          <MenuItem value="select" disabled>
                            Select year *
                          </MenuItem>
                          {decreasingYearsList()?.map((year) => (
                            <MenuItem key={year} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      {/* <div
                        className={`form-input _pc ${
                          errorFields.includes("") ? "_error" : ""
                        }`}
                        style={{ visibility: "hidden" }}
                      >
                        <Typography>Days on market for</Typography>
                        <TextField
                          name="days_on_market_for"
                          defaultValue={updateData?.days_on_market_for || ""}
                          onChange={updateFormData}
                          fullWidth
                          placeholder="Days on market for"
                          type="number"
                        />
                      </div> */}
                    </div>
                    <div className="form-row">
                      <div
                        className={`form-input ${
                          errorFields.includes("lease_term") ? "_error" : ""
                        }`}
                      >
                        <Typography>
                          Lease term (no. of years left on the title)
                        </Typography>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl css-1q3t5gr-MuiInputBase-root-MuiOutlinedInput-root">
                          <NumericFormat
                            name="lease_term"
                            defaultValue={updateData?.lease_term || ""}
                            // onChange={updateFormData}
                            onValueChange={(values) => {
                              const { formattedValue, value, floatValue } =
                                values;
                              updateFormData({
                                target: {
                                  name: "lease_term",
                                  value,
                                },
                              });
                              // do something with floatValue
                            }}
                            placeholder="No. of years left on the title"
                            thousandSeparator
                            valueIsNumericString
                            allowNegative={false}
                            className="_input MuiInputBase-input MuiOutlinedInput-input css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input"
                          />
                        </div>
                      </div>
                      <div
                        className={`form-input ${
                          errorFields.includes("year_built") ? "_error" : ""
                        }`}
                        style={
                          inputData.property_type === "land"
                            ? { visibility: "hidden" }
                            : { visibility: "visible" }
                        }
                      >
                        <Typography>Year built *</Typography>
                        <Select
                          name="year_built"
                          defaultValue={updateData?.year_built || "select"}
                          onChange={updateFormData}
                          fullWidth
                        >
                          <MenuItem value="select" disabled>
                            Select year *
                          </MenuItem>
                          {decreasingYearsList()?.map((year) => (
                            <MenuItem key={year} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      {/* <div
                        className={`form-input _pc ${
                          errorFields.includes("") ? "_error" : ""
                        }`}
                        style={{ visibility: "hidden" }}
                      >
                        <Typography>Days on market for</Typography>
                        <TextField
                          name="days_on_market_for"
                          defaultValue={updateData?.days_on_market_for || ""}
                          onChange={updateFormData}
                          fullWidth
                          placeholder="Days on market for"
                          type="number"
                        />
                      </div> */}
                    </div>
                    <div className="form-row">
                      <div
                        className={`form-input ${
                          errorFields.includes("description") ? "_error" : ""
                        }`}
                      >
                        <Typography>Description * (max 250 chars)</Typography>
                        <TextField
                          name="description"
                          defaultValue={updateData?.description || ""}
                          onChange={updateFormData}
                          fullWidth
                          multiline
                          inputProps={{ maxLength: 250 }}
                          rows={5}
                          placeholder="Type here..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ display: step === 2 ? "block" : "none" }}>
                <div>
                  <div className="page-header">
                    <Typography variant="h4">
                      Select all features that apply
                    </Typography>
                  </div>
                  <div className="page-body _features">
                    {Object.keys(loadedFeatures).map(
                      (featureGroup: string, outdex: number) => (
                        <div key={outdex} className="feature-group-box">
                          <Typography variant="h5">{featureGroup}</Typography>
                          <div className="feature-group">
                            {loadedFeatures[featureGroup].map(
                              (feature: IPropertyFeatures, index: number) => (
                                <div className="feature-item" key={index}>
                                  {/* <CheckBox  /> */}
                                  <Checkbox
                                    name="features"
                                    defaultChecked={updateData?.features?.includes(
                                      feature.id
                                    )}
                                    id={feature.id.toString()}
                                    value={feature.id}
                                    onChange={updateCheckboxes}
                                  />
                                  <label htmlFor={feature.id.toString()}>
                                    <Typography>{feature.name}</Typography>
                                  </label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>

              <div style={{ display: step === 3 ? "block" : "none" }}>
                <div>
                  <div className="page-header">
                    <Typography variant="h4">
                      Upload all the necessary files and documents
                    </Typography>
                  </div>
                  <div className="page-body">
                    <div
                      className={`list-group-box ${
                        errorFields.includes("uploads") ? "_error" : ""
                      }`}
                    >
                      <Typography variant="h5">Property images</Typography>
                      <div className="list-group">
                        {new Array(
                          Math.max(imageFiles.length, INITIAL_UPLOAD_ICONS)
                        )
                          .fill(null)
                          .map((item: null, index: number) => (
                            <UploadedItem
                              key={`${index}-image`}
                              files={imageFiles}
                              type="image"
                              index={index}
                              updateData={updateData}
                              updateFormData={updateFormData}
                              deleteItem={() => deleteItem("image", index)}
                            />
                          ))}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <input
                          ref={uploadImagesRef}
                          type="file"
                          hidden
                          id="images"
                          onChange={handleFileImageChange}
                          accept=".png,.jpeg,.jpg,.bmp"
                          multiple
                        />

                        <Button
                          variant="contained"
                          onClick={() => uploadImagesRef.current.click()}
                        >
                          <Add style={{ fontSize: 28, marginRight: 5 }} />
                          Upload images
                        </Button>
                      </div>
                    </div>
                    <div
                      className={`list-group-box ${
                        errorFields.includes("uploads") ? "_error" : ""
                      }`}
                    >
                      <Typography variant="h5">Property videos</Typography>
                      <div className="list-group">
                        {new Array(
                          Math.max(videoFiles.length, INITIAL_UPLOAD_ICONS)
                        )
                          .fill(null)
                          .map((item: null, index: number) => (
                            <UploadedItem
                              key={`${index}-video`}
                              files={videoFiles}
                              type="video"
                              index={index}
                              updateData={updateData}
                              updateFormData={updateFormData}
                              deleteItem={() => deleteItem("video", index)}
                            />
                          ))}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <input
                          ref={uploadVideosRef}
                          type="file"
                          hidden
                          id="videos"
                          onChange={handleFileVideoChange}
                          accept=".mp4,.mpeg,.mov,.avi,.wmv"
                          multiple
                        />
                        <Button
                          variant="contained"
                          onClick={() => uploadVideosRef.current.click()}
                        >
                          <Add style={{ fontSize: 28, marginRight: 5 }} />{" "}
                          Upload videos
                        </Button>
                      </div>
                    </div>

                    <div className="list-group-box" style={{ paddingTop: 30 }}>
                      <div className="form-row single-line">
                        <Typography>Add YouTube/Vimeo links</Typography>
                        {videoLinksList.map((videoLink, index) => (
                          <div
                            key={`videoLink-${index}`}
                            className="form-input"
                            style={{
                              display: "flex",
                              gap: 10,
                              marginBottom: 20,
                            }}
                          >
                            <TextField
                              key={`video-${videoLinksList.length}-${index}`}
                              fullWidth
                              type="url"
                              defaultValue={videoLink}
                              onChange={(evt) =>
                                updateLink("video", "edit", index, evt)
                              }
                            />
                            {index === 0 ? (
                              <IconButton
                                onClick={() => updateLink("video", "add")}
                                style={{
                                  backgroundColor: "#066D0E",
                                  borderRadius: 4,
                                  color: "#FFFFFF",
                                  width: 48,
                                }}
                              >
                                <Add />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() =>
                                  updateLink("video", "remove", index)
                                }
                                style={{
                                  backgroundColor: "#066D0E",
                                  borderRadius: 4,
                                  color: "#FFFFFF",
                                  width: 48,
                                }}
                              >
                                <Close />
                              </IconButton>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="form-row single-line">
                        <Typography>Add Virtual Tour embed links</Typography>
                        {embedLinksList.map((embedLink, index) => (
                          <div
                            key={`embedLink-${index}`}
                            className="form-input"
                            style={{
                              display: "flex",
                              gap: 10,
                              marginBottom: 20,
                            }}
                          >
                            <TextField
                              key={`video-${embedLinksList.length}-${index}`}
                              type="url"
                              fullWidth
                              defaultValue={embedLink}
                              onChange={(evt) =>
                                updateLink("embed", "edit", index, evt)
                              }
                            />
                            {index === 0 ? (
                              <IconButton
                                onClick={() => updateLink("embed", "add")}
                                style={{
                                  backgroundColor: "#066D0E",
                                  borderRadius: 4,
                                  color: "#FFFFFF",
                                  width: 48,
                                }}
                              >
                                <Add />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() =>
                                  updateLink("embed", "remove", index)
                                }
                                style={{
                                  backgroundColor: "#066D0E",
                                  borderRadius: 4,
                                  color: "#FFFFFF",
                                  width: 48,
                                }}
                              >
                                <Close />
                              </IconButton>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="list-group-box" style={{ paddingTop: 30 }}>
                      <Typography
                        variant="h4"
                        style={{ fontSize: 18, fontWeight: 600 }}
                      >
                        Property Documents
                      </Typography>
                      <Typography variant="body2">
                        Please note that the documents will ONLY be shared with
                        interested buyers after payment
                      </Typography>
                    </div>
                    {documentsTypes.map((docType) => (
                      <div
                        className={`list-group-box ${
                          errorFields.includes(docType.key) ? "_error" : ""
                        }`}
                        key={docType.key}
                      >
                        <Typography variant="h5">
                          {docType.title}{" "}
                          {docType.key === "title_document"
                            ? "(CofO, RofO, or Governor's Consent)"
                            : ""}{" "}
                        </Typography>
                        <div className="list-group">
                          <CustomUpload
                            id={docType.key}
                            name={docType.title}
                            updateFiles={updateDocuments}
                            savedFiles={updateData}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : isLoadingDetails ? (
            <div style={{ padding: 20, textAlign: "center" }}>
              L O A D I N G . . .
            </div>
          ) : (
            isLoadingDetailsError && (
              <div style={{ padding: 20, textAlign: "center" }}>
                Error loading details. Please check your connection and try
                again.
              </div>
            )
          )}
        </div>

        {!!errorMsgs?.length && (
          <div className="error-banner">
            {errorMsgs?.map((msg: string) => (
              <div>{msg}</div>
            ))}
            <div className="close-error-banner" onClick={clearErrorBanner}>
              <Close />
            </div>
          </div>
        )}

        {(!listingId || updateData?.name) && (
          <div className="dasboard-base" style={{ flexDirection: "column" }}>
            {!!errorFields.length && <div>* Fill all required fields *</div>}
            <Button
              variant="contained"
              disabled={isBusy || !!errorFields.length}
              onClick={doSubmitData}
              className="btn-width-300"
            >
              {`${step >= steps ? "Submit" : "Save & Continue"}`}
              <Loading show={isBusy} />
            </Button>
          </div>
        )}

        <SuccessModal
          open={showSuccessModal}
          handleClose={() => setShowSuccessModal(false)}
          title="Listing submitted"
        />
      </>
    </SellerDashboardLayout>
  );
};

export default ListProperty;
