import React, { ChangeEvent, useEffect, useState } from "react";
import AdminDashboardLayout from "../../commonComponents/DashboardLayout";
import DevelopersTable from "./components/DevelopersTable";
import CustomModal from "../../../common/components/CustomModal";
import DeveloperApplication from "./DeveloperApplication";
import {
  IAPIResponse,
  IDeveloperDetails,
} from "../../../common/utils/interfaces";
import { getSellersDetails } from "../../../../apis/admin/sellers";
import { useLocation } from "react-router-dom";

const Developers: React.FC = () => {
  const location = useLocation();

  const [detailData, setDetailData] = useState<IDeveloperDetails | null>(null);
  const [fullDetailData, setFullDetailData] =
    useState<IDeveloperDetails | null>(null);
  const [filterState, setFilterState] = useState<any>({
    approved: false,
    pending: false,
    rejected: false,
  });

  /* useEffect(() => {
    const fetchData = async () => {
      await getSellersList();
    };

    fetchData();
  }, [filterState]); */

  useEffect(() => {
    if (location.state) {
      const { developer } = location?.state || {};

      if (developer) {
        setDetailData(developer);
      }
    }
  }, [location]);

  useEffect(() => {
    if (detailData) {
      fetchData();
    }
  }, [detailData]);

  const fetchData = async () => {
    const result: IAPIResponse | undefined = await getSellersDetails(
      detailData
    );

    if (result) {
      const { data, count } = result;
      setFullDetailData(data);
    }
  };

  const onFilter = (evt: ChangeEvent<HTMLInputElement>, state: string) => {
    setFilterState({ ...filterState, [state]: evt.target.checked });
  };

  return (
    <AdminDashboardLayout
      onFilter={onFilter}
      hasHeader
      searchPlaceholder="Applications"
    >
      <>
        <div className="page-central">
          <div>
            <DevelopersTable setDetailData={(value) => setDetailData(value)} />
          </div>
        </div>
        <CustomModal
          showModal={!!fullDetailData}
          onCloseModal={() => setFullDetailData(null)}
        >
          <DeveloperApplication
            detailData={fullDetailData}
            closeModal={() => setFullDetailData(null)}
          />
        </CustomModal>
      </>
    </AdminDashboardLayout>
  );
};

export default Developers;

//canary black
//lakatabu
//citadel diana
//hounds of war
//beast of two worlds, ajanaku
//die hart
