import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import BuyerDashboardLayout from "../../components/DashboardLayout";
import TransactionsTable from "./components/TransactionsTable";

const Transactions: React.FC = () => {
  const navigate = useNavigate();
  const [detailData, setDetailData] = useState<any>(null);
  const [fullDetailData, setFullDetailData] = useState<any>(null);
  const [filterState, setFilterState] = useState<any>({
    approved: false,
    pending: false,
    rejected: false,
  });

  useEffect(() => {
    /* const fetchData = async () => {
      const result: IAPIResponse | undefined = await getMyListingDetails(
        detailData
      );

      if (result) {
        const { data, count } = result;
        setFullDetailData(data);
      }
    }; */

    if (detailData) {
      // fetchData();
    }
  }, [detailData]);

  const onFilter = (evt: ChangeEvent<HTMLInputElement>, state: string) => {
    setFilterState({ ...filterState, [state]: evt.target.checked });
  };

  const showTable = useMemo(
    () => <TransactionsTable setDetailData={(value) => setDetailData(value)} />,
    []
  );

  return (
    <BuyerDashboardLayout>
      <>
        <div className="page-central">
          <div>{showTable}</div>
        </div>
        {/* <CustomModal
          showModal={!!fullDetailData}
          onCloseModal={() => setFullDetailData(null)}
        >
          <PropertyListingApplication
            psuedoData={detailData}
            fullDetailData={fullDetailData}
            closeModal={() => setFullDetailData(null)}
          />
        </CustomModal> */}
      </>
    </BuyerDashboardLayout>
  );
};

export default Transactions;
