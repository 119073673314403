import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import DocumentVerification from "../../modals/DocumentVerification";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import {
  addCompanyInfo,
  fetchLgas,
  fetchStates,
  fetchTowns,
  sellerSignUp,
  uploadDocuments,
} from "../../../../apis/accounts/auth";

import { signUpFailure } from "../../../../store/slices/buyer/userSlice";
import AccountVerification from "../../../Buyer/modals/AccountVerification";
import { Link, useNavigate } from "react-router-dom";
import { getMyProfile } from "../../../../apis/accounts/user";
import { IAPIResponse } from "../../../common/utils/interfaces";

function SignUp() {
  const [formInput, setFormInput] = useState({
    last_name: "",
    first_name: "",
    email: "",
    password1: "",
    password2: "",
    phoneNumber: "",
    companyName: "",
    companyNumber: "",
    companyAddress: "",
    companyWebsite: "",
    companyState: "",
    companyLGA: "",
    businessType: "",
  });
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();

  const [isLoading, setIsLoading] = useState(false);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const emailIsValid = emailRegex.test(formInput.email);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [displayModals, setDisplayModals] = useState({
    accountVerification: false,
    documentVerification: false,
  });
  const fieldsUnfilled =
    !formInput.email ||
    !formInput.first_name ||
    !formInput.last_name ||
    !phoneNumber ||
    !formInput.password1 ||
    !formInput.password2;
  const openModal = (modalName: string) =>
    setDisplayModals((prev) => ({ ...prev, [modalName]: true }));
  const closeModal = (modalName: string) =>
    setDisplayModals((prev) => ({ ...prev, [modalName]: false }));
  const closeAllModal = () =>
    setDisplayModals({
      accountVerification: false,
      documentVerification: false,
    });
  const passwordMatch = formInput.password1 === formInput.password2;

  const [currentScreen, setCurrentScreen] = useState<
    "companyDetails" | "personalDetails" | "uploadFiles"
  >("personalDetails");
  const [errorMsg, setErrorMsg] = useState("");
  const { loading, error } = useSelector((state: RootState) => state.user);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormInput((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const lowercaseRegex = /[a-z]/;
  const hasLowerCase = lowercaseRegex.test(formInput.password1);
  const uppercaseRegex = /[A-Z]/;
  const hasUpperCase = uppercaseRegex.test(formInput.password1);
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const hasSpecialCharacter = specialCharRegex.test(formInput.password1);

  const toCompanyDetails = () => {
    if (!passwordMatch) return setShowPasswordError(true);
    setCurrentScreen("companyDetails");
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const signUp = async () => {
    setErrorMsg("");
    if (fieldsUnfilled) {
      setErrorMsg("Please fill all required fields");
      return;
    }
    if (!passwordMatch) return setErrorMsg("Passwords do not match");
    if (!emailIsValid) return setErrorMsg("Enter valid email");
    if (
      !hasLowerCase ||
      !hasUpperCase ||
      !hasSpecialCharacter ||
      formInput.password1.length < 12
    )
      return setErrorMsg("Password does not meet required format");
    setIsLoading(true);
    const isSuccess = await dispatch(
      sellerSignUp(
        formInput.email,
        formInput.first_name,
        formInput.last_name,
        phoneNumber || "",
        "NG",
        formInput.password1,
        formInput.password2
      )
    );
    setIsLoading(false);
    isSuccess && openModal("accountVerification");
  };
  useEffect(() => {
    const handleDragOver = (event: DragEvent) => {
      event.preventDefault(); // Prevent default drag behavior
    };

    const handleDrop = (event: DragEvent) => {
      event.preventDefault(); // Prevent default file drop behavior
      console.log("File drop prevented.");
    };

    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("drop", handleDrop);

    return () => {
      // Cleanup event listeners on component unmount
      window.removeEventListener("dragover", handleDragOver);
      window.removeEventListener("drop", handleDrop);
    };
  }, []);

  return (
    <div className="pt-10 relative min-h-[100vh]">
      <img
        src="/assets/images/background.svg"
        className="absolute top-0 left-0 z-[-1] h-full w-full object-cover"
        alt=""
      />
      <div className="flex space-x-4 items-center justify-center">
        <Link to="/" className="contents">
          <img className="h-[40px]" src="/assets/images/logo.svg" alt="" />
          <div className="flex flex-col items-center italic uppercase text-[#084E14] font-[600] text-[16px]">
            <h4>Home</h4>
            <div className="flex space-x-1 items-center">
              <div className="w-[54px] h-[2px] bg-custom-gradient" />
              <span className="bg-custom-gradient text-[16px] bg-clip-text text-transparent">
                &
              </span>
              <div className="w-[54px] h-[2px] bg-custom-gradient" />
            </div>
            <h4>Abroad</h4>
          </div>
        </Link>
      </div>
      <h3 className="text-primary text-center font-[500] text-[16px]">
        Simplifying Property Purchases in Nigeria
      </h3>
      <section className="mt-[20px] bg-white px-4 sm:px-[35px] pt-[10px] pb-5 shadow-md rounded-[20px] mx-auto max-w-[1000px]">
        <div
          onClick={handleGoBack}
          className="flex items-center w-fit mt-3 duration-300 space-x-3 hover:translate-x-2 cursor-pointer"
        >
          <img
            src="/assets/images/chevron-left.svg"
            className="opacity-55"
            alt=""
          />
          <span className="text-lg text-textBlack2">Back</span>
        </div>
        <div className="mt-8">
          <h2 className="text-textBlack font-[600] text-[18px]">
            Create a Seller Account
          </h2>
          <p className="text-textGrey text-[14px] mb-5">
            Fill all input fields correctly
          </p>
          {currentScreen === "personalDetails" && (
            <>
              <div className="grid sm:grid-cols-2 gap-[15px]">
                <div>
                  <label htmlFor="first_name" className="custom__label">
                    First Name*
                  </label>
                  <input
                    value={formInput.first_name}
                    onChange={handleInputChange}
                    type="text"
                    id="first_name"
                    className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
                  />
                </div>
                <div>
                  <label htmlFor="last_name" className="custom__label">
                    Last Name*
                  </label>
                  <input
                    type="text"
                    onChange={handleInputChange}
                    value={formInput.last_name}
                    id="last_name"
                    className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
                  />
                </div>
                <div>
                  <label htmlFor="phoneNumber" className="custom__label">
                    Phone Number*
                  </label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    international
                    value={phoneNumber}
                    className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1] focus:outline-none"
                    onChange={setPhoneNumber}
                    defaultCountry="NG"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="custom__label">
                    Email*
                  </label>
                  <input
                    value={formInput.email}
                    onChange={handleInputChange}
                    id="email"
                    type="email"
                    className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
                  />
                </div>
                <div className="">
                  <label htmlFor="password1" className="custom__label">
                    Password*
                  </label>
                  <div className="relative">
                    <input
                      value={formInput.password1}
                      onChange={handleInputChange}
                      id="password1"
                      type={showPassword ? "text" : "password"}
                      className="custom__input"
                    />
                    {showPassword ? (
                      <img
                        onClick={togglePasswordVisibility}
                        className="password__eye"
                        src="/assets/images/eye-open.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        onClick={togglePasswordVisibility}
                        className="password__eye"
                        src="/assets/images/eye-closed.svg"
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div className="">
                  <label htmlFor="password2" className="custom__label">
                    Confirm Password*
                  </label>
                  <div className="relative">
                    <input
                      id="password2"
                      value={formInput.password2}
                      onChange={handleInputChange}
                      type={showConfirmPassword ? "text" : "password"}
                      className="custom__input"
                    />
                    {showConfirmPassword ? (
                      <img
                        onClick={toggleConfirmPasswordVisibility}
                        className="password__eye"
                        src="/assets/images/eye-open.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        onClick={toggleConfirmPasswordVisibility}
                        className="password__eye"
                        src="/assets/images/eye-closed.svg"
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </div>
              {showPasswordError && (
                <span className="text-[#FF0000] text-center block mt-1">
                  Password does not match!
                </span>
              )}

              {formInput.password1 && (
                <div className="flex flex-col gap-y-1 text-red-500">
                  <span className={`${hasLowerCase && "hidden"}`}>
                    Password must contain small letter
                  </span>
                  <span
                    className={`${
                      formInput.password1.length >= 12 && "hidden"
                    }`}
                  >
                    Password must not be less than 12 characters
                  </span>
                  <span className={`${hasUpperCase && "hidden"}`}>
                    Password must contain capital letter
                  </span>
                  <span className={`${hasSpecialCharacter && "hidden"}`}>
                    Password must contain special characters
                  </span>
                </div>
              )}
              <div className="mt-[30px] mb-[5px]">
                {errorMsg && (
                  <span className="text-red-500 block text-center">
                    {errorMsg}
                  </span>
                )}
                {error &&
                  error.map((msg) => (
                    <span className="text-red-500 block text-center">
                      {msg}
                    </span>
                  ))}
                <button
                  type="button"
                  disabled={fieldsUnfilled}
                  onClick={signUp}
                  className="filled-button block w-full mx-auto sm:max-w-[50%] py-[10px] disabled:opacity-35"
                >
                  {isLoading ? <span className="loader"></span> : "Continue"}
                </button>
              </div>
              <p className="text-center text-textBlack font-[500] text-[12px]">
                Already have an account?{" "}
                <span className="text-primary">
                  <Link to="/sign-in?redirect=/seller">Sign In</Link>
                </span>
              </p>
            </>
          )}
          {currentScreen === "companyDetails" && (
            <CompanyDetails
              handleNext={() => setCurrentScreen("uploadFiles")}
            />
          )}
          {currentScreen === "uploadFiles" && <UploadFiles />}
        </div>
        <AccountVerification
          handleClose={() => {}}
          open={displayModals.accountVerification}
          submitAction={() => {
            closeModal("accountVerification");
            toCompanyDetails();
          }}
          isSeller={true}
          email={formInput.email}
        />
      </section>
    </div>
  );
}

export default SignUp;

interface UploadFilesProps {
  closeOnboardingModal?: () => void;
  isLoggedIn?: boolean;
}

export const UploadFiles: React.FC<UploadFilesProps> = ({
  closeOnboardingModal,
  isLoggedIn,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [displayModals, setDisplayModals] = useState({
    accountVerification: false,
    documentVerification: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [files, setFiles] = useState({
    certificate_of_incorporation: null,
    real_estate_license: null,
    proof_of_address: null,
    tax_identification_number: null,
    tax_clearance_cert: null,
    directors_valid_id: null,
  });
  const [error, setError] = useState<string | null>(null);

  const allDocumentsUploaded =
    Object.values(files).filter((file) => file === null).length < 1;
  const openModal = (modalName: string) =>
    setDisplayModals((prev) => ({ ...prev, [modalName]: true }));
  const closeModal = (modalName: string) =>
    setDisplayModals((prev) => ({ ...prev, [modalName]: false }));
  const closeAllModal = () =>
    setDisplayModals({
      accountVerification: false,
      documentVerification: false,
    });

  const showVerificationModal = () => {
    Object.values(files).forEach((file) => {
      if (file === null) {
        setErrorMsg("Please upload all required files");
      }
    });

    if (allDocumentsUploaded) {
      setErrorMsg("");
      dispatch(signUpFailure([""]));
      openModal("documentVerification");
    }
  };

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    fileName: string
  ) => {
    const file = event.target.files?.[0];
    const maxFileSize = 10 * 1024 * 1024;
    if (file) {
      if (file?.size > maxFileSize) {
        return alert("file size should not be more than 10 MB");
      }
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      // Check if file type is PDF
      if (
        fileExtension !== "pdf" &&
        fileExtension !== "jpg" &&
        fileExtension !== "jpeg" &&
        fileExtension !== "png"
      ) {
        alert(`${fileName} must be a pdf, jpeg, jpg, or png file`);
        return;
      }

      setFiles((prevFiles) => ({
        ...prevFiles,
        [fileName]: file,
      }));
      setError(null);
    }
  };

  const uploadFiles = async () => {
    const requestSuccesful = await dispatch(uploadDocuments(files));
    return requestSuccesful;
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // Handle drop event
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const fileName = e.target.offsetParent.querySelector("input")?.id;

    // Get files from the drop event
    const droppedFiles = Array.from(e.dataTransfer.files);
    const file: any = droppedFiles[0];

    if (file) {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      if (
        fileExtension !== "pdf" &&
        fileExtension !== "jpg" &&
        fileExtension !== "jpeg" &&
        fileExtension !== "png"
      ) {
        alert(`${fileName} must be a pdf, jpeg, jpg, or png file`);
        return;
      }

      setFiles((prevFiles) => ({
        ...prevFiles,
        [fileName]: file,
      }));
      setError(null);
    }
  };

  return (
    <div>
      <div className="grid mt-[25px] sm:grid-cols-3 gap-y-[25px] sm:gap-x-[35px]">
        <div className="">
          <p className="text-[16px] text-textBlack font-medium">
            Certificate of Incorporation*
          </p>
          <div className="file-container relative">
            <div
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDrop={handleDrop}
              className="absolute w-full h-full z-1"
            ></div>
            <img src="/assets/images/document-upload.svg" alt="" />
            <h3 className="file-container__title">
              {files.certificate_of_incorporation
                ? // @ts-ignore
                  files.certificate_of_incorporation.name
                : "Choose a file or drag it and drop here"}
            </h3>
            <span className="file-container__info text-center">
              JPG, PNG or PDF readable files only <br /> (Upload Limit: 10MB)
            </span>
            <input
              onChange={(e) =>
                handleFileChange(e, "certificate_of_incorporation")
              }
              className="hidden"
              type="file"
              accept=".jpg, .png, .jpeg, .pdf"
              name=""
              id={"certificate_of_incorporation"}
            />
            <label
              htmlFor="certificate_of_incorporation"
              className="file-container__button z-10"
            >
              Browse File
            </label>
          </div>
        </div>
        <div className="">
          <p className="text-[16px] text-textBlack font-medium">
            Real Estate Practitioners License*
          </p>
          <div className="file-container relative">
            <div
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDrop={handleDrop}
              className="absolute w-full h-full z-1"
            ></div>
            <img src="/assets/images/document-upload.svg" alt="" />
            <h3 className="file-container__title">
              {files.real_estate_license
                ? // @ts-ignore
                  files.real_estate_license.name
                : "Choose a file or drag it and drop here"}
            </h3>
            <span className="file-container__info text-center">
              JPG, PNG or PDF readable files only <br /> (Upload Limit: 10MB)
            </span>
            <input
              onChange={(e) => handleFileChange(e, "real_estate_license")}
              className="hidden"
              type="file"
              accept=".jpeg, .jpg, .png, .pdf"
              name=""
              id={"real_estate_license"}
            />
            <label
              htmlFor="real_estate_license"
              className="file-container__button z-10"
            >
              Browse File
            </label>
          </div>
        </div>
        <div className="">
          <p className="text-[16px] text-textBlack font-medium">
            Proof of Address*
          </p>
          <div className="file-container relative">
            <div
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDrop={handleDrop}
              className="absolute w-full h-full z-1"
            ></div>
            <img src="/assets/images/document-upload.svg" alt="" />
            <h3 className="file-container__title">
              {files.proof_of_address
                ? // @ts-ignore
                  files.proof_of_address.name
                : "Choose a file or drag it and drop here"}
            </h3>
            <span className="file-container__info text-center">
              JPG, PNG or PDF readable files only <br /> (Upload Limit: 10MB)
            </span>
            <input
              onChange={(e) => handleFileChange(e, "proof_of_address")}
              className="hidden"
              type="file"
              accept=".jpeg, .jpg, .png, .pdf"
              name=""
              id={"proof_of_address"}
            />
            <label
              htmlFor="proof_of_address"
              className="file-container__button z-10"
            >
              Browse File
            </label>
          </div>
        </div>
        <div className="">
          <p className="text-[16px] text-textBlack font-medium">
            Tax Identification Number (TIN)*
          </p>
          <div className="file-container relative">
            <div
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDrop={handleDrop}
              className="absolute w-full h-full z-1"
            ></div>
            <img src="/assets/images/document-upload.svg" alt="" />
            <h3 className="file-container__title">
              {files.tax_identification_number
                ? // @ts-ignore
                  files.tax_identification_number.name
                : "Choose a file or drag it and drop here"}
            </h3>
            <span className="file-container__info text-center">
              JPG, PNG or PDF readable files only <br /> (Upload Limit: 10MB)
            </span>
            <input
              onChange={(e) => handleFileChange(e, "tax_identification_number")}
              className="hidden"
              type="file"
              accept=".jpeg, .jpg, .png, .pdf"
              name=""
              id={"tax_identification_number"}
            />
            <label
              htmlFor="tax_identification_number"
              className="file-container__button z-10"
            >
              Browse File
            </label>
          </div>
        </div>
        <div className="">
          <p className="text-[16px] text-textBlack font-medium">
            Recent Tax Clearance Certificate*
          </p>
          <div className="file-container relative">
            <div
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDrop={handleDrop}
              className="absolute w-full h-full z-1"
            ></div>
            <img src="/assets/images/document-upload.svg" alt="" />
            <h3 className="file-container__title">
              {files.tax_clearance_cert
                ? // @ts-ignore
                  files.tax_clearance_cert.name
                : "Choose a file or drag it and drop here"}
            </h3>
            <span className="file-container__info text-center">
              JPG, PNG or PDF readable files only <br /> (Upload Limit: 10MB)
            </span>
            <input
              onChange={(e) => handleFileChange(e, "tax_clearance_cert")}
              className="hidden"
              type="file"
              accept=".jpeg, .jpg, .png, .pdf"
              name=""
              id={"tax_clearance_cert"}
            />
            <label
              htmlFor="tax_clearance_cert"
              className="file-container__button z-10"
            >
              Browse File
            </label>
          </div>
        </div>
        <div className="">
          <p className="text-[16px] text-textBlack font-medium">
            Director’s Valid ID*
          </p>
          <div className="file-container relative">
            <div
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDrop={handleDrop}
              className="absolute w-full h-full z-1"
            ></div>
            <img src="/assets/images/document-upload.svg" alt="" />
            <h3 className="file-container__title">
              {files.directors_valid_id
                ? // @ts-ignore
                  files.directors_valid_id.name
                : "Choose a file or drag it and drop here"}
            </h3>
            <span className="file-container__info text-center">
              JPG, PNG or PDF readable files only <br /> (Upload Limit: 10MB)
            </span>
            <input
              onChange={(e) => handleFileChange(e, "directors_valid_id")}
              className="hidden"
              type="file"
              accept=".jpeg, .jpg, .png, .pdf"
              name=""
              id={"directors_valid_id"}
            />
            <label
              htmlFor="directors_valid_id"
              className="file-container__button z-10"
            >
              Browse File
            </label>
          </div>
        </div>
      </div>
      {errorMsg && <span className="text-red-500 block">{errorMsg}</span>}
      <button
        type="button"
        disabled={!allDocumentsUploaded}
        onClick={showVerificationModal}
        className="filled-button col-span-3 w-full block mx-auto sm:w-[456px] mt-[30px] mb-[5px] disabled:opacity-35"
      >
        {isLoading ? <span className="loader"></span> : "Continue"}
      </button>
      <DocumentVerification
        isLoggedIn={isLoggedIn}
        handleClose={() => closeModal("documentVerification")}
        open={displayModals.documentVerification}
        uploadDocument={uploadFiles}
        closeOnboardingModal={() =>
          closeOnboardingModal && closeOnboardingModal()
        }
      />
    </div>
  );
};

interface CompanyDetailsProps {
  handleNext: () => void;
}
export const CompanyDetails: React.FC<CompanyDetailsProps> = ({
  handleNext,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const user = JSON.parse(localStorage.getItem("user") || "{}")
  const [formInput, setFormInput] = useState({
    name: userInfo?.company_name || "",
    regNumber: userInfo?.company_registration_number || "",
    businessType: userInfo?.business_type || "",
    website: userInfo?.website_url || "",
    address: userInfo?.address || "",
    city: userInfo?.city || "",
    lga: userInfo?.lga?.id || "",
    state: userInfo?.state?.id || "",
  });
  const { error } = useSelector((state: RootState) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [statesList, setStatesList] = useState([]);
  const [townsList, setTownsList] = useState([]);
  const [lgaList, setLgaList] = useState([]);
  const dispatch: AppDispatch = useDispatch();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormInput((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };
  const [errorMsg, setErrorMsg] = useState("");
  function validateURL(url: string): boolean {
    const pattern = new RegExp(
      "^(https?://)?" +
        "((([a-zA-Z0-9-]+)\\.)+[a-zA-Z]{2,})" +
        "(:[0-9]{1,5})?" +
        "(/[^\\s]*)?$",
      "i"
    );
    return pattern.test(url);
  }
  const handleSelectChange = (e: SelectChangeEvent) => {
    setFormInput((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const fieldsUnfilled =
    !formInput.name ||
    !formInput.regNumber ||
    !formInput.businessType ||
    !formInput.address ||
    !formInput.city ||
    !formInput.lga ||
    !formInput.state;
  const submitCompanyDetails = async () => {
    setErrorMsg("");
    if (formInput.website && !validateURL(formInput.website)) {
      setErrorMsg("Enter valid url");
      return;
    }
    if (fieldsUnfilled) {
      setErrorMsg("Fill all required fields");
      return;
    }
    setIsLoading(true);
    const response = await dispatch(
      addCompanyInfo(
        formInput.name,
        formInput.regNumber,
        formInput.businessType,
        formInput.website,
        formInput.address,
        formInput.city,
        formInput.lga,
        formInput.state
      )
    );
    setIsLoading(false);
    if (response?.status) {
      handleNext();
    }
  };

  useEffect(() => {
    async function getStates() {
      const response = await fetchStates();
      if (response) setStatesList(response);
    }
    async function getProfile() {
      const response: IAPIResponse | undefined = await getMyProfile();
      if (response?.status) {
        const { data } = response;
        setFormInput({
          name: data?.company_name || "",
          regNumber: data?.company_registration_number || "",
          businessType: data?.business_type || "",
          website: data?.website_url || "",
          address: data?.address || "",
          city: data?.city || "",
          lga: data?.lga?.id || "",
          state: data?.state?.id || "",
        });
      }
    }
    if(user?.first_name)getProfile();
    getStates();
  }, [user?.first_name]);

  useEffect(() => {
    async function getTownsAndLgas() {
      const townResponse = await fetchTowns(formInput.state);
      if (townResponse) setTownsList(townResponse);
      const lgaResponse = await fetchLgas(formInput.state);
      if (lgaResponse) setLgaList(lgaResponse);
    }
    if (formInput.state) getTownsAndLgas();
  }, [statesList, formInput.state]);
  return (
    <>
      <div className="grid sm:grid-cols-2 gap-[15px] mt-[26px]">
        <div>
          <label htmlFor="name" className="custom__label">
            Company Name*
          </label>
          <input
            value={formInput.name}
            onChange={handleInputChange}
            type="text"
            id="name"
            className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
          />
        </div>
        <div>
          <label htmlFor="regNumber" className="custom__label">
            Company Registration Number*
          </label>
          <input
            type="text"
            onChange={handleInputChange}
            value={formInput.regNumber}
            id="regNumber"
            className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
          />
        </div>
        <div>
          <label htmlFor="businessType" className="custom__label">
            Business Type*
          </label>
          <Select
            value={formInput.businessType}
            id="businessType"
            name="businessType"
            onChange={handleSelectChange}
            style={{ width: "100%", height: 48, borderRadius: 8 }}
          >
            <MenuItem value="sole_proprietor">Sole - Proprietor</MenuItem>
            <MenuItem value="partnership">Partnership</MenuItem>
            <MenuItem value="llc">LLC</MenuItem>
            <MenuItem value="plc">PLC</MenuItem>
          </Select>
        </div>
        <div>
          <label htmlFor="website" className="custom__label">
            Website URL(optional)
          </label>
          <input
            type="url"
            onChange={handleInputChange}
            placeholder="https://google.com"
            value={formInput.website}
            id="website"
            className="w-full placeholder:text-gray-300 outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
          />
        </div>
        <div>
          <label htmlFor="address" className="custom__label">
            Address*
          </label>
          <input
            value={formInput.address}
            onChange={handleInputChange}
            id="address"
            type="text"
            className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
          />
        </div>
        <div>
          <label htmlFor="state" className="custom__label">
            State*
          </label>
          <Select
            value={formInput.state}
            id="state"
            name="state"
            onChange={handleSelectChange}
            style={{ width: "100%", height: 48, borderRadius: 8 }}
          >
            {statesList.length > 0 &&
              statesList.map((state: { id: string; name: string }) => (
                <MenuItem key={state.id} value={state.id}>
                  {state.name}
                </MenuItem>
              ))}
          </Select>
        </div>
        <div>
          <label htmlFor="city" className="custom__label">
            City*
          </label>
          <input
            value={formInput.city}
            onChange={handleInputChange}
            id="city"
            type="text"
            className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
          />
        </div>
        <div>
          <label htmlFor="lga" className="custom__label">
            LGA*
          </label>
          <Select
            value={formInput.lga}
            id="lga"
            name="lga"
            onChange={handleSelectChange}
            style={{ width: "100%", height: 48, borderRadius: 8 }}
          >
            {lgaList.length > 0 &&
              lgaList.map((lga: { id: string; name: string }) => (
                <MenuItem key={lga.id} value={lga.id}>
                  {lga.name}
                </MenuItem>
              ))}
          </Select>
        </div>
      </div>
      <div className="mt-[30px]">
        {errorMsg && (
          <span className="text-red-500 block text-center">{errorMsg}</span>
        )}
        {error &&
          error.map((msg) => (
            <span className="text-red-500 block text-center">{msg}</span>
          ))}
        <button
          disabled={fieldsUnfilled}
          onClick={submitCompanyDetails}
          className="uppercase block w-full mx-auto sm:max-w-[50%] bg-primary disabled:opacity-35 text-textWhite rounded-[10px] mt-[5px] mb-[5px] py-[10px] text-[14px] font-[600]"
        >
          {isLoading ? <span className="loader"></span> : "Continue"}
        </button>
      </div>
    </>
  );
};
