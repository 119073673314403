export const BACKEND_URL = process.env.REACT_APP_ENDPOINT;
// export const BACKEND_URL = "https://coolify.homeandabroad.ng/api";

export const APP_CURRENCIES_RATES: any = {
  NGN: 1,
  USD: 1688.74,
  GBP: 2139.87,
  EUR: 1783.36,
  CAD: 1207.09,
}; // $1 => N1730
export const APP_CURRENCIES = Object.freeze({
  NGN: "NGN",
  USD: "USD",
  GBP: "GBP",
  EUR: "EUR",
  CAD: "CAD",
});
export const APP_DEFAULT_CURRENCY = APP_CURRENCIES.NGN;
export const APP_INACTIVITY_MINUTES = 5;

export const USER_TYPES = Object.freeze({
  ADMIN: "admin",
  SELLER: "seller",
  BUYER: "buyer",
});

export const MAX_FILE_SIZE = 10; //  (in MB)

export const MAX_DASHBOARD_LISTINGS_ADMIN = 3;
export const MAX_DASHBOARD_LISTINGS_SELLER = 3;

export const developerDocuments: any = {
  "Certificate of Incorporation": "certificate_of_incorporation",
  "Real Estate License": "real_estate_license",
  "Proof of Address": "proof_of_address",
  "Tax Identification Number (TIN)": "tax_identification_number",
  "Recent Tax Clearance Certificate": "tax_clearance_cert",
  "Director’s Valid ID": "directors_valid_id",
};

export const listingDocuments: any = {
  "Building Permit": "building_permit",
  // "Certificate of Ownership": "certificate_of_ownership",
  // "Inspection Certificates": "inspection_certificates",
  // "Proof of Property Insurance": "proof_of_property_insurance",
  "Title Document": "title_document",
  "Valuation Report": "valuation_report",
  "Legal Search Report": "legal_search_report",
};

export const documentsTypes: { title: string; key: string }[] = [
  {
    title: "Title Document",
    key: "title_document",
  },
  {
    title: "Building Permit",
    key: "building_permit",
  },
  {
    title: "Valuation Report",
    key: "valuation_report",
  },
  {
    title: "Legal Search Report",
    key: "legal_search_report",
  },
];

export const sortOptions: { key: string; title: string }[] = [
  { key: "nwst", title: "Newest Listings" },
  { key: "oldst", title: "Oldest Listings" },
  { key: "plth", title: "Price: Low to High" },
  { key: "phtl", title: "Price: High to Low" },
  { key: "pop", title: "Popularity" },
  { key: "sflth", title: "Square Meter: Low to High" },
  { key: "sfhtl", title: "Square Meter: High to Low" },
];

export const PAYMENT_TYPES = Object.freeze({
  VIRTUAL_TOUR: "virtual_tour",
  PHYSICAL_TOUR: "physical_tour",
});
