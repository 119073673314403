import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../types";
import { HighlightOffOutlined } from "@mui/icons-material";
import StateModalUnit from "./StateModalUnit";

interface StateModalProps extends CustomModalProps {
  selectedStates?: any[];
}
const StateModal: React.FC<StateModalProps> = ({
  handleClose,
  open,
  statesList,
  updateStateFilterList,
  selectedStates,
}) => {
  const firstColumn = ["A", "D", "G", "K"];
  const secondColumn = ["B", "E", "I", "L", "P"];
  const thirdColumn = ["C", "F", "J", "N", "R", "Y"];

  return (
    <Modal onClose={handleClose} open={open}>
      <div className="absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] w-[90%] sm:w-[1124px] outline-none">
        <HighlightOffOutlined
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            fontSize: 25,
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
        <div className=" bg-[#E8EDE9] h-[85vh] overflow-y-auto sm:h-fit w-full rounded-[10px] py-10 px-6 sm:px-[50px]">
          <div className="w-full hidden sm:flex justify-between">
            <div className="flex flex-col gap-y-[45px]">
              {firstColumn.map((startingChar) => {
                return (
                  <div className="flex gap-x-[25px]">
                    <h2 className="h2">{startingChar}</h2>
                    <div className="bg-white card-shadow rounded w-[242px]">
                      {statesList
                        ?.filter((stateData) =>
                          stateData.name
                            ?.toLowerCase()
                            ?.startsWith(startingChar.toLowerCase())
                        )
                        ?.map((stateData, index) => (
                          <StateModalUnit
                            key={`${stateData.code}-${index}`}
                            stateData={stateData}
                            updateList={updateStateFilterList}
                            filterSelected={selectedStates?.find(
                              (state) => state.value === stateData.id
                            )}
                          />
                        ))}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col gap-y-[45px]">
              {secondColumn.map((startingChar) => {
                return (
                  <div className="flex gap-x-[25px]">
                    <h2 className="h2">{startingChar}</h2>
                    <div className="bg-white card-shadow rounded w-[242px]">
                      {statesList
                        ?.filter((stateData) =>
                          stateData.name
                            ?.toLowerCase()
                            ?.startsWith(startingChar.toLowerCase())
                        )
                        ?.map((stateData, index) => (
                          <StateModalUnit
                            key={`${stateData.code}-${index}`}
                            stateData={stateData}
                            updateList={updateStateFilterList}
                            filterSelected={selectedStates?.find(
                              (state) => state.value === stateData.id
                            )}
                          />
                        ))}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col gap-y-[45px]">
              {thirdColumn.map((startingChar) => {
                return (
                  <div className="flex gap-x-[25px]">
                    <h2 className="h2">{startingChar}</h2>
                    <div className="bg-white card-shadow rounded w-[242px]">
                      {statesList
                        ?.filter((stateData) =>
                          stateData.name
                            ?.toLowerCase()
                            ?.startsWith(startingChar.toLowerCase())
                        )
                        ?.map((stateData, index) => (
                          <StateModalUnit
                            key={`${stateData.code}-${index}`}
                            stateData={stateData}
                            updateList={updateStateFilterList}
                            filterSelected={selectedStates?.find(
                              (state) => state.value === stateData.id
                            )}
                          />
                        ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col gap-y-6 sm:hidden">
            {[...firstColumn, ...secondColumn, ...thirdColumn]?.map(
              (startingChar) => {
                return (
                  <div className="">
                    <h2 className="h2">{startingChar}</h2>
                    <div className="bg-white card-shadow rounded w-full">
                      {statesList
                        ?.filter((stateData) =>
                          stateData.name
                            ?.toLowerCase()
                            ?.startsWith(startingChar.toLowerCase())
                        )
                        ?.map((stateData, index) => (
                          <StateModalUnit
                            key={`${stateData.code}-${index}`}
                            stateData={stateData}
                            updateList={updateStateFilterList}
                          />
                        ))}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StateModal;

function searchByKeys(array: any[], keys: string[]) {
  return array.filter((obj) => keys.some((key) => key in obj));
}
