import { BACKEND_URL } from "../../views/common/utils/constants";
import { getQueryStrings } from "../../views/common/utils/helpers";
import {
  IAPIResponse,
  IFilterFetch,
} from "../../views/common/utils/interfaces";
import { errorToastify } from "../../views/common/utils/Toasty";
import { CustomAxios } from "../customAxios";

export const getAdminBuyerTransactions = async (filter?: IFilterFetch) => {
  try {
    const { searchQuery, filterQuery } = getQueryStrings(filter);

    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/admin/transactions?${searchQuery}${filterQuery}`
    );

    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      // errorToastify(error);
    }
  }
};
