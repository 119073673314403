import { BACKEND_URL } from "../../views/common/utils/constants";
import { IAPIResponse } from "../../views/common/utils/interfaces";
import { errorToastify } from "../../views/common/utils/Toasty";
import { CustomAxios } from "../customAxios";

export const getFeaturesList = async () => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/listings/features/`
    );

    // const result = tempDummy;

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      // errorToastify(error);
    }
  }
};

export const getPropertyTypesList = async () => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/listings/property-types`
    );

    // const result = tempDummy;

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      // errorToastify(error);
    }
  }
};

export const getPropertyTypeClassificationsList = async () => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/listings/property-types/classifications/`
    );

    // const result = tempDummy;

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      // errorToastify(error);
    }
  }
};

export const getPropertyTypeSubClassificationsList = async () => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/listings/property-types/classifications/subs`
    );

    // const result = tempDummy;

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      // errorToastify(error);
    }
  }
};

export const getReceipt = async (url: string) => {
  try {
    const response: IAPIResponse = await CustomAxios().get(url, {
      responseType: "blob",
    });

    // const result = tempDummy;

    /* if (!response.data?.status) {
      throw new Error(response.data?.message);
    } */

    // Create a Blob from the response data
    const pdfBlob = new Blob([response.data], { type: "application/pdf" });

    // Create a temporary URL for the Blob
    const fileUrl = window.URL.createObjectURL(pdfBlob);

    // Create a temporary <a> element to trigger the download
    const tempLink = document.createElement("a");
    tempLink.href = fileUrl;
    tempLink.setAttribute("download", `receipt_${new Date().getTime()}.pdf`); // Set the desired filename for the downloaded file

    // Append the <a> element to the body and click it to trigger the download
    document.body.appendChild(tempLink);
    tempLink.click();

    // Clean up the temporary elements and URL
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(fileUrl);

    // return result;
  } catch (error) {
    if (typeof error === "string") {
      // errorToastify(error);
    }
  }
};
