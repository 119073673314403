import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SearchState {
  layoutSearchText: string;
  layoutStatusFilter: {
    approved?: boolean;
    pending?: boolean;
    rejected?: boolean;
  };
}
const initialState: SearchState = {
  layoutSearchText: "",
  layoutStatusFilter: {},
};

export const layoutSearchSlice = createSlice({
  name: "searchLayout",
  initialState,
  reducers: {
    setLayoutSearchText: (state, action: PayloadAction<string>) => {
      state.layoutSearchText = action.payload;
    },
    setLayoutStatusFilter: (
      state,
      action: PayloadAction<{
        approved?: boolean;
        pending?: boolean;
        rejected?: boolean;
      }>
    ) => {
      state.layoutStatusFilter = action.payload;
    },
  },
});

export const { setLayoutSearchText, setLayoutStatusFilter } =
  layoutSearchSlice.actions;
export default layoutSearchSlice.reducer;
