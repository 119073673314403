import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import site_logo from "../../../../assets/images/site_logo.svg";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  HeaderIconFilter,
  HeaderIconSearch,
  IconNotificationBell,
} from "../LayoutIcons";
import CustomBadge from "../CustomBadge";
import { IUserType } from "../../utils/interfaces";
import MobileNav from "../../../Seller/components/MobileNav";
import { INavItem } from "../../global/DashboardRoutes";
import { AddCircle, CloseOutlined } from "@mui/icons-material";
import { logoutUser } from "../../../../apis/accounts/auth";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import {
  setLayoutSearchText,
  setLayoutStatusFilter,
} from "../../../../store/slices/general/searchSlice";
import UseDebouncedValue from "../UseDebouncedValue";

const upperCaseString = (value: string) => {
  if (!value) {
    return "Dashboard";
  }

  value = value.replace("-", " ");
  const returnedValue = value
    .split(" ")
    .map((eachString: string) => {
      return `${eachString?.[0]?.toUpperCase()}${eachString?.substring(1)}`;
    })
    .join(" ");

  return returnedValue;
};

const DashboardLayout = ({
  section,
  noPadding,
  navigationMenu,
  children,
  hasHeader = false,
  pageHeader,
  pageBase,
  isCenter = false,
  searchPlaceholder,
  onSearch,
  onFilter,
}: {
  section: IUserType;
  noPadding?: boolean;
  navigationMenu: INavItem[];
  hasHeader?: boolean;
  pageHeader?: { mainText: string; subText?: string; flankRight?: string };
  pageBase?: JSX.Element;
  isCenter?: boolean;
  searchPlaceholder?: string;
  onSearch?: () => void;
  onFilter?: (filterList: string[]) => void;
  children: JSX.Element;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchTerm = UseDebouncedValue(searchQuery);

  const [currentFilter, setCurrentFilter] = useState<string[]>([]);

  const [pageName, setPageName] = useState("");
  const [showFullSearchBar, setShowFullSearchBar] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(setLayoutSearchText(""));
    dispatch(setLayoutStatusFilter({}));

    const pagePath = location.pathname.split("/")[2];
    const locationPage = upperCaseString(pagePath);
    setPageName(locationPage);

    document.title = `Home & Abroad | ${upperCaseString(locationPage)}`;
  }, [location.pathname]);

  useEffect(() => {
    dispatch(setLayoutSearchText(debouncedSearchTerm));
  }, [debouncedSearchTerm]);

  const updateFilter = (filter: string, evt: ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    evt.stopPropagation();

    let currentFilter_ = [...currentFilter];

    if (currentFilter_.includes(filter)) {
      currentFilter_.splice(currentFilter_.indexOf(filter), 1);
    } else {
      currentFilter_.push(filter);
    }

    setCurrentFilter(currentFilter_);
    dispatch(
      setLayoutStatusFilter({
        approved: currentFilter_.includes("approved"),
        pending: currentFilter_.includes("pending"),
        rejected: currentFilter_.includes("rejected"),
      })
    );
  };

  return (
    <div className={`dashboard-layout ${section}`}>
      {!noPadding && (
        <div className="sm:hidden ml-auto">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "10px 5px 10px 15px",
              boxShadow: "0 2px 10px #eee",
              position: "fixed",
              width: "100%",
              zIndex: 1001,
              backgroundColor: "#ffffff",
            }}
          >
            {showFullSearchBar ? (
              <div
                className="central-search"
                style={{
                  marginBottom: 0,
                  marginRight: 10,
                  maxWidth: "unset",
                  width: "100%",
                  paddingRight: 15,
                }}
              >
                <TextField fullWidth placeholder="Search listings" />
                <HeaderIconSearch />
                <CloseOutlined
                  style={{ marginLeft: 15, color: "#999999" }}
                  onClick={() => setShowFullSearchBar(false)}
                />
              </div>
            ) : (
              <>
                <div style={{ width: 50, marginRight: 15 }}>
                  <img src={site_logo} alt="logo" />
                </div>
                <Typography
                  style={{
                    fontSize: 16,
                    marginRight: "auto",
                    textTransform: "capitalize",
                  }}
                >
                  {pageName || "Dashboard"}
                </Typography>
                {hasHeader && (
                  <>
                    <div
                      className="dashboard-header-search"
                      onClick={() => setShowFullSearchBar(true)}
                    >
                      <HeaderIconSearch />
                    </div>
                    {!["seller"].includes(section) && (
                      <Button
                        className="custom-button"
                        style={{ padding: "9px 10px" }}
                      >
                        <HeaderIconFilter />
                        <span className="_pc _no-tab">Filter</span>
                      </Button>
                    )}
                    <Button
                      variant="text"
                      className="custom-button _pc _no-tab"
                    >
                      Clear all
                    </Button>
                    {/* <Button variant="contained" className="_pc _no-tab">
                      Save search
                    </Button> */}
                  </>
                )}
                {["seller"].includes(section) && (
                  <div
                    className="dashboard-header-search"
                    onClick={() => setShowFullSearchBar(true)}
                  >
                    <AddCircle
                      className="_green-img"
                      style={{ fontSize: 22, marginRight: 7 }}
                      onClick={() => navigate("/seller/list-property")}
                    />
                    {!hasHeader && <HeaderIconSearch />}
                  </div>
                )}
                {["admin", "seller"].includes(section) && (
                  <div className="icon-holder" style={{ margin: 0 }}>
                    <IconNotificationBell style={{ width: 24 }} />
                  </div>
                )}
                <MobileNav source={section} />
              </>
            )}
          </div>
        </div>
      )}

      {hasHeader && (
        <div className="dashboard-header _pc">
          <div className="dashboard-header-search">
            <TextField
              fullWidth
              className="_pc"
              placeholder={searchPlaceholder}
              value={searchQuery}
              onChange={
                onSearch ? onSearch : (evt) => setSearchQuery(evt.target.value)
              }
            />
            <HeaderIconSearch />
          </div>
          <Button className="custom-button" onClick={handleClick}>
            <HeaderIconFilter />
            <span className="_pc _no-tab">Filter</span>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem>
              <Checkbox
                id="approved"
                checked={currentFilter.includes("approved")}
                onChange={(evt) => updateFilter("approved", evt)}
              />{" "}
              <label htmlFor="approved">Approved</label>
            </MenuItem>
            <MenuItem>
              <Checkbox
                id="rejected"
                checked={currentFilter.includes("rejected")}
                onChange={(evt) => updateFilter("rejected", evt)}
              />{" "}
              <label htmlFor="rejected">Rejected</label>
            </MenuItem>
            <MenuItem>
              <Checkbox
                id="pending"
                checked={currentFilter.includes("pending")}
                onChange={(evt) => updateFilter("pending", evt)}
              />{" "}
              <label htmlFor="pending">Pending</label>
            </MenuItem>
          </Menu>
          <Button variant="text" className="custom-button _pc _no-tab">
            Clear all
          </Button>
          {/* <Button variant="contained" className="_pc _no-tab">
            Save search
          </Button> */}
        </div>
      )}

      <div className="dashboard-core">
        <div className="dashboard-sidenav">
          <div className="dashboard-sidenav-inner">
            <div className="dashboard-logo-block">
              <Link to={`/${section}`}>
                <img src={site_logo} alt="homeandabroad logo" />
              </Link>
            </div>
            <div className="div-divider"></div>
            <div className="dashboard-nav-items">
              {navigationMenu.map((navItem, index: number) => (
                <NavLink
                  to={navItem.url}
                  key={index}
                  className="dashboard-nav-item"
                  style={{
                    ...(navItem.title === "Logout"
                      ? { marginTop: "auto" }
                      : {}),
                  }}
                  onClick={
                    navItem.title === "Logout"
                      ? (evt) => {
                          evt.stopPropagation();
                          evt.preventDefault();

                          dispatch(logoutUser());
                          navigate("/");
                        }
                      : () => {}
                  }
                >
                  <div className="icon-box">{navItem.icon}</div>
                  <Typography style={{ color: "inherit" }}>
                    {navItem.title}
                  </Typography>
                </NavLink>
              ))}
            </div>
          </div>
        </div>
        <div
          className={`dashboard-main-wrapper ${noPadding ? "_no-padding" : ""}`}
        >
          {pageHeader && (
            <div className="dashboard-page-title">
              <Typography variant="h4">{pageHeader?.mainText}</Typography>
              {pageHeader?.subText && (
                <CustomBadge mode="success" style={{ marginLeft: 13 }}>
                  <>{pageHeader.subText}</>
                </CustomBadge>
              )}
              {pageHeader?.flankRight && (
                <span className="flank-right">{pageHeader.flankRight}</span>
              )}
            </div>
          )}
          <div
            className="dashboard-main"
            style={isCenter ? { justifyContent: "center" } : {}}
          >
            {/* {hasHeader && <div className="dashboard-header">DASHBOARD HEADER</div>} */}
            {children}
          </div>
          {pageBase && <div className="dasboard-base">{pageBase}</div>}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
