import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { ListingInterface, ListingsInterface } from "../../../types";
import coordinatesData from "../../data/coordinates.json";

interface IMap {
  center?: any;
  detailsPage?: boolean;
  listings?: ListingsInterface[] | ListingInterface[];
  selectListing?: (listing: ListingsInterface | ListingInterface) => void;
}
const MyGoogleMap: React.FC<IMap> = ({
  center,
  listings,
  selectListing,
  detailsPage,
}) => {
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const { states } = coordinatesData;
  let centerCoordinate;
  if (listings?.length! > 0) {
    centerCoordinate = states.find((state) =>
      state.name.includes(listings?.[0].state!)
    );
  } else {
    centerCoordinate = {
      latitude: 9.0765,
      longitude: 7.3986,
    };
  }
  if (center === undefined || center.lat === null || center.lng === null) {
    center = {
      lat: centerCoordinate?.latitude,
      lng: centerCoordinate?.longitude,
    };
  }
  if (!detailsPage) {
    center = {
      lat: 9.082,
      lng: 8.6753,
    };
  }

  const MAP_API_KEY = "AIzaSyCxAxT-kcT7FANseSEYGzeSerAXiNSJND8";
  return (
    <APIProvider apiKey={MAP_API_KEY}>
      <Map
        style={{ width: "100%", height: "100%" }}
        defaultCenter={center}
        defaultZoom={detailsPage ? 12 : 5.5}
        fullscreenControl={false}
        // disableDefaultUI={true}
      >
        {/* <Marker position={center} /> */}
        {listings?.map((listing, index) => {
          let listingCoordinates = {
            lat: listing.latitude,
            lng: listing.longitude,
          };
          const stateCoordinate = states.find((state) =>
            state.name.includes(listing.state)
          );
          if (
            listingCoordinates.lat === null ||
            listingCoordinates.lng === null
          ) {
            listingCoordinates.lat = stateCoordinate?.latitude!;
            listingCoordinates.lng = stateCoordinate?.longitude!;
          }
          return (
            <Marker
              position={{
                lat: listingCoordinates.lat,
                lng: listingCoordinates.lng,
              }}
              onClick={() => selectListing && selectListing(listing)}
            />
          );
        })}
      </Map>
    </APIProvider>
  );
};

export default MyGoogleMap;
