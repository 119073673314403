import { BACKEND_URL } from "../../views/common/utils/constants";
import { getQueryStrings } from "../../views/common/utils/helpers";
import {
  IAPIResponse,
  IFilterFetch,
} from "../../views/common/utils/interfaces";
import { errorToastify } from "../../views/common/utils/Toasty";
import { CustomAxios } from "../customAxios";

export const getListingsList = async (filter?: IFilterFetch) => {
  try {
    const { searchQuery, filterQuery } = getQueryStrings(filter);

    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/admin/listings/?${searchQuery}${filterQuery}`
    );

    // const result = tempDummyList;

    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const getListingDetails = async (inputData: any) => {
  const { id, url } = inputData || {};

  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      id
        ? `${BACKEND_URL}/admin/listings/${id}/`
        : url?.replace("http:", "https:")
    );

    // const result = tempDummyDetail;

    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const postListingApplicationAction_ = async ({
  sellerUrl,
  action,
  reason,
}: {
  sellerUrl: string;
  action: string;
  reason?: string;
}) => {
  try {
    const result: IAPIResponse = await CustomAxios().post(
      // `${BACKEND_URL}/admin/listings/${sellerUrl}`,
      sellerUrl?.replace("http:", "https:"),
      {
        action,
        reason,
      }
    );
    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const postListingApplicationAction = async ({
  sellerUrl,
  action,
  reason: rejection_reason,
}: {
  sellerUrl: string;
  action: string;
  reason?: string;
}) => {
  try {
    const result: IAPIResponse = await CustomAxios().post(
      // `${BACKEND_URL}/admin/listings/${sellerUrl}`,
      `${sellerUrl}update-status`?.replace("http:", "https:"),
      { action, ...(action === "reject" ? { rejection_reason } : {}) }
    );
    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};
