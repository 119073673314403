import React, { useState } from "react";
import moment from "moment";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import CustomBadge from "../../../../common/components/CustomBadge";
import CustomTable, {
  HeadCell,
} from "../../../../common/components/CustomTable/CustomTable";
import {
  IAPIResponse,
  IDeveloperDetails,
  IFilterFetch,
  ISellerListing,
} from "../../../../common/utils/interfaces";
import {
  getSellersDetails,
  getSellersList,
} from "../../../../../apis/admin/sellers";
import { formatDate } from "../../../../common/utils/helpers";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";

type Props = {
  setDetailData: (arg: IDeveloperDetails) => void;
};

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "company_name",
    numeric: false,
    disablePadding: true,
    label: "Company Name",
  },
  {
    id: "created",
    numeric: true,
    disablePadding: false,
    label: "Submission Date",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Approval Status",
    align: "center",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
];

const DevelopersTable: React.FC<Props> = ({ setDetailData }: Props) => {
  const { layoutSearchText, layoutStatusFilter } = useSelector(
    (state: RootState) => state.search
  );

  const [tableData, setTableData] = useState<ISellerListing[]>([]);

  const tableTitle = {
    mainText: "Developers",
    subText: "developers",
  };

  React.useEffect(() => {
    fetchTableData();
  }, [layoutSearchText, layoutStatusFilter]);

  const fetchTableData = async () => {
    const result: IAPIResponse | undefined = await getSellersList({
      filter: layoutStatusFilter,
      search: layoutSearchText,
    });

    if (result) {
      const { data, count } = result;
      setTableData(data);
    }
  };

  const getBadgeMode = (text: string) => {
    switch (text) {
      case "approved":
        return "success";

      case "rejected":
        return "error";

      default:
        return "info";
    }
  };

  const selectData = (
    evt: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    selectedData: any
  ) => {
    evt.stopPropagation();

    setDetailData(selectedData);
  };

  const MappedTableRows = ({
    row,
    labelId,
    isItemSelected,
  }: {
    row: IDeveloperDetails;
    labelId: string;
    isItemSelected: boolean;
  }) => {
    return (
      <>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          {`${row.first_name} ${row.last_name}`}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          {`${row.company_name || "--"}`}
        </TableCell>
        {/* temporarily hidden */}
        {/* <TableCell align="right">{row.user_type}</TableCell> */}
        <TableCell align="right">
          {formatDate(row.submission_date || row.created)}
        </TableCell>
        <TableCell align="center">
          <CustomBadge mode={getBadgeMode(row.status)}>
            {row.status}
          </CustomBadge>
        </TableCell>
        <TableCell align="center">
          {/* <Link to="/admin/developers/application" className="hover_underline"> */}
          <Typography
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              gap: 10,
            }}
            onClick={(evt) => selectData(evt, row)}
          >
            View details
          </Typography>
          {/* </Link> */}
        </TableCell>
      </>
    );
  };

  return (
    <CustomTable
      tableTitle={tableTitle}
      headCells={headCells}
      rows={tableData}
      mappedTableRows={MappedTableRows}
    />
  );
};

export default DevelopersTable;
