import axios from "axios";
import { AppDispatch } from "../../store/store";
import apiRoutes from "../../views/common/utils/apiRoutes";
import {
  fetchListings,
  fetchListingsFailure,
  fetchListingsSuccess,
} from "../../store/slices/buyer/listingsSlice";
import {
  fetchListing,
  fetchListingFailure,
  fetchListingSuccess,
} from "../../store/slices/buyer/listingSlice";
import { CustomAxios } from "../customAxios";
import {
  IAPIResponse,
  IFilterFetch,
} from "../../views/common/utils/interfaces";
import { BACKEND_URL } from "../../views/common/utils/constants";
import { errorToastify } from "../../views/common/utils/Toasty";
import { getQueryStrings } from "../../views/common/utils/helpers";

export const getListings =
  (queryString = "") =>
  async (dispatch: AppDispatch) => {
    try {
      let response;
      const access_token = localStorage.getItem("access_token");
      dispatch(fetchListings());
      if (access_token !== null) {
        response = await CustomAxios().get(
          `${apiRoutes.buyerListing.loggedIn}${queryString}`
        );
      } else {
        response = await axios.get(
          `${apiRoutes.buyerListing.view}${queryString}`
        );
      }
      dispatch(fetchListingsSuccess(response.data.data));
    } catch (error: any) {
      dispatch(
        fetchListingsFailure(
          error.response?.data?.message || "Could not fetch listings"
        )
      );
    }
  };

export const getAuthListings =
  (queryString = "") =>
  async (dispatch: AppDispatch) => {
    try {
      const { data: result }: IAPIResponse = await CustomAxios().get(
        `${apiRoutes.buyerListing.loggedIn}${queryString}`
      );

      if (!result?.status) {
        throw new Error(result?.message);
      }

      dispatch(fetchListingsSuccess(result.data));
      return result;
    } catch (error) {
      if (typeof error === "string") {
        errorToastify(error);
      }
    }
  };

export const toggleSaveListing =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      const axios = CustomAxios();
      const response = await axios.post(`/listings/${id}/toggle-save`);
      dispatch(toggleSaveListing(id));
    } catch (error: any) {}
  };

export const getListing = (id: string) => async (dispatch: AppDispatch) => {
  try {
    let response;
    const access_token = localStorage.getItem("access_token");
    dispatch(fetchListing());
    if (access_token !== null) {
     response = await CustomAxios().get(`${apiRoutes.buyerListing.view}/${id}`);
    } else {
      response = await axios.get(`${apiRoutes.buyerListing.view}/${id}`);
    }
    dispatch(fetchListingSuccess(response.data.data));
  } catch (error: any) {
    dispatch(
      fetchListingFailure(
        error.response?.data?.message || "Could not fetch listing"
      )
    );
  }
};

export const getSavedListings = async (layoutSearchText?: string) => {
  try {
    const searchString = layoutSearchText ? `?search=${layoutSearchText}` : "";
    const { data: result }: IAPIResponse = await CustomAxios().get(
      // `${BACKEND_URL}/listings?saved_by_user=true`
      `${BACKEND_URL}/listings/saved${searchString}`
    );

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const postToggleSaveListing = async ({
  id,
  url,
}: {
  id?: string;
  url?: string;
}) => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().post(
      `${id ? `${BACKEND_URL}/listings/${id}` : url}/toggle-save`
    );
    // const result = tempDummy;

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const getBuyerProperties = async (layoutSearchText?: string) => {
  try {
    const searchString = layoutSearchText ? `?search=${layoutSearchText}` : "";

    const { data: result }: IAPIResponse = await CustomAxios().get(
      // `${BACKEND_URL}/listings?saved_by_user=true`
      `${BACKEND_URL}/listings/buyer-properties/${searchString}`
    );

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const getBuyerTransactions = async (filter?: IFilterFetch) => {
  try {
    const { searchQuery, filterQuery } = getQueryStrings(filter);

    const { data: result }: IAPIResponse = await CustomAxios().get(
      // `${BACKEND_URL}/listings?saved_by_user=true`
      `${BACKEND_URL}/payments/buyer-transactions?${searchQuery}${filterQuery}`
    );

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};
