import { BACKEND_URL } from "../../views/common/utils/constants";
import { getQueryStrings } from "../../views/common/utils/helpers";
import {
  IAPIResponse,
  IFilterFetch,
} from "../../views/common/utils/interfaces";
import { errorToastify } from "../../views/common/utils/Toasty";
import { CustomAxios } from "../customAxios";

export const getMyListingDetails = async (inputData: any) => {
  const { id, url } = inputData || {};

  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      // `${BACKEND_URL}/admin/listings/${id}/`
      id
        ? `${BACKEND_URL}/listings/seller-listings/${id}/`
        : url?.replace("http:", "https:")
    );

    // const result = tempDummyDetail;

    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const postListingApplicationAction = async ({
  sellerUrl,
  action,
  reason,
}: {
  sellerUrl: string;
  action: string;
  reason?: string;
}) => {
  try {
    const result: IAPIResponse = await CustomAxios().post(
      `${BACKEND_URL}/admin/listings/${sellerUrl}`,
      {
        action,
        reason,
      }
    );
    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const getMyListings = async (filter?: IFilterFetch) => {
  try {
    const { searchQuery, filterQuery } = getQueryStrings(filter);

    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/listings/seller-listings/?${searchQuery}${filterQuery}`
    );

    // const result = tempDummy;

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const postNewListing = async (data: any) => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().post(
      `${BACKEND_URL}/listings/create?is_for_creation=true&page=1`,
      data,
      { headers: { "Content-Type": "multipart/form-data" } }
    );

    // const result = tempDummy;

    if (!result?.status) {
      // throw new Error(result?.message);
      return result;
    }
    return result;
  } catch (error: any) {
    if (typeof error === "string") {
      errorToastify(error);
    }
    return error?.response?.data || error?.response;
  }
};

export const updateListing = async (
  listingId: string,
  data: any,
  isForCreation: boolean,
  pageNumber: number
) => {
  try {
    const isForCreationQuery = isForCreation ? "?is_for_creation=true" : "";
    const pageQuery = isForCreation ? `&page=${pageNumber}` : "";

    const { data: result }: IAPIResponse = await CustomAxios().patch(
      listingId.includes("http")
        ? `${listingId.replace(
            "http:",
            "https:"
          )}update${isForCreationQuery}${pageQuery}`
        : `${BACKEND_URL}/listings/seller-listings/${listingId}/update${isForCreationQuery}${pageQuery}`,
      data,
      { headers: { "Content-Type": "multipart/form-data" } }
    );

    // const result = tempDummy;

    if (!result?.status) {
      return result;
    }
    return result;
  } catch (error: any) {
    if (typeof error === "string") {
      errorToastify(error);
    }
    return error?.response?.data || error?.response;
  }
};

export const getSellerDashboardInfo = async () => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/listings/seller-dashboard/info`
    );

    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      // errorToastify(error);
    }
  }
};

export const postApplicationDelistEnlist = async ({
  id,
  action,
  reason,
}: {
  id: string;
  action: string;
  reason?: string;
}) => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().post(
      `${BACKEND_URL}/listings/seller-listings/${id}/toggle-listing-status`,
      {
        action,
        reason,
      }
    );
    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};
