import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import AdminDashboardLayout from "../../commonComponents/DashboardLayout";
import PropertyListingsTable from "./components/PropertyListingsTable";
import CustomModal from "../../../common/components/CustomModal";
import PropertyListingApplication from "./PropertyListingApplication";
import {
  IAPIResponse,
  IListing,
  IPropertyListing,
} from "../../../common/utils/interfaces";
import { getListingDetails } from "../../../../apis/admin/listings";

const PropertyListings: React.FC = () => {
  const location = useLocation();
  const queries = location.search;
  const lid = new URLSearchParams(queries).get("lid");

  const [detailData, setDetailData] = useState<IPropertyListing | null>(null);
  const [fullDetailData, setFullDetailData] = useState<IListing | null>(null);
  const [filterState, setFilterState] = useState<any>({
    approved: false,
    pending: false,
    rejected: false,
  });

  useEffect(() => {
    if (location.state) {
      const listing = location?.state || {};

      if (listing) {
        setDetailData(listing);
      }
    }
  }, [location]);

  useEffect(() => {
    if (detailData) {
      fetchData();
    } else if (lid) {
      fetchData(lid);
    }
  }, [detailData, lid]);

  const fetchData = async (lid?: string) => {
    const result: IAPIResponse | undefined = await getListingDetails(
      lid ? { id: lid } : detailData
    );

    if (result) {
      const { data, count } = result;
      setFullDetailData(data);
    }
  };

  const showTable = useMemo(
    () => (
      <PropertyListingsTable setDetailData={(value) => setDetailData(value)} />
    ),
    []
  );

  return (
    <AdminDashboardLayout hasHeader searchPlaceholder="Search listings">
      <>
        <div className="page-central">
          <div>{showTable}</div>
        </div>
        <CustomModal
          showModal={!!fullDetailData}
          onCloseModal={() => {
            setDetailData(null);
            setFullDetailData(null);
          }}
        >
          <PropertyListingApplication
            psuedoData={detailData}
            fullDetailData={fullDetailData}
            closeModal={() => {
              setDetailData(null);
              setFullDetailData(null);
            }}
          />
        </CustomModal>
      </>
    </AdminDashboardLayout>
  );
};

export default PropertyListings;
