import { Button, Popover } from "@mui/material";
import React from "react";

const SupportDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        style={{ padding: "9px 15px", fontSize: 14, minWidth: "unset" }}
      >
        <div className="flex gap-x-2">
          <span className="capitalize text-textBlack">Support</span>
          <img src="/assets/images/chevron-down.svg" alt="" />
        </div>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="py-5 px-[30px]">
          <div className="flex flex-col gap-y-[22px] text-[16px] text-textBlack">
            <div className="flex items-center">
              <img src="/assets/images/chat.svg" alt="" />
              <p className="ml-5">Chat</p>
              {/* <span className="ml-[204px] bg-gray-300 py-1 px-[10px] rounded-full">
                Offline
              </span> */}
            </div>
            <a
              href="https://wa.me/+2348091311111"
              className="flex items-center"
            >
              <img src="/assets/images/whatsapp.svg" alt="" />
              <p className="ml-5">+234 809 131 1111</p>
            </a>
            <a href="tel:+2348091311111" className="flex items-center">
              <img src="/assets/images/phone.svg" alt="" />
              <p className="ml-5">+234 809 131 1111</p>
            </a>
            <a
              href="mailto:support@homeandabroad.ng"
              className="flex items-center"
            >
              <img src="/assets/images/mail2.svg" alt="" />
              <p className="ml-5">support@homeandabroad.ng</p>
            </a>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default SupportDropdown;
