import React, { useState } from "react";
import UserDropdown from "../modals/UserDropdown";
import SupportDropdown from "../../Seller/modals/SupportDropdown";
import Marquee from "react-fast-marquee";
import MobileNav from "../../Seller/components/MobileNav";
import { Link } from "react-router-dom";
import CurrencySwitch from "../../common/components/CurrencySwitch";

const HomeLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div>
      <Navbar />
      {children}
      <div className="bg-[#BCD5BE] py-[22px] overflow-x-hidden mt-10">
        <Marquee autoFill speed={100} className="">
          <div className="flex space-x-14 pr-14">
            <img
              className="h-[78px]"
              src="/assets/images/brain-hammers.png"
              alt=""
            />
            <img
              className="h-[78px]"
              src="/assets/images/mixta-africa.png"
              alt=""
            />
            <img
              className="h-[78px]"
              src="/assets/images/post-service.png"
              alt=""
            />
            <img
              className="h-[78px]"
              src="/assets/images/modern-shelter.png"
              alt=""
            />
            <img
              className="h-[78px]"
              src="/assets/images/urban-shelter.png"
              alt=""
            />
            <img
              className="h-[78px]"
              src="/assets/images/brain-hammers.png"
              alt=""
            />
            <img
              className="h-[78px]"
              src="/assets/images/mixta-africa.png"
              alt=""
            />
            <img
              className="h-[78px]"
              src="/assets/images/post-service.png"
              alt=""
            />
            <img
              className="h-[78px]"
              src="/assets/images/modern-shelter.png"
              alt=""
            />
            <img
              className="h-[78px]"
              src="/assets/images/urban-shelter.png"
              alt=""
            />
          </div>
        </Marquee>
      </div>
      <footer className="py-[30px] px-4 sm:px-10 lg:px-[175px]">
        <section className="sm:grid grid-cols-7">
          <div className="col-span-3 lg:col-span-2 flex justify-between items-center sm:block">
            <div className="flex flex-row sm:flex-col items-center w-fit text-[17px] font-semibold italic text-primary">
              <img src="/assets/images/logo.svg" className="h-[50px]" alt="" />
              <div className="flex flex-col items-center sm:contents nav-logo">
                <p className="">HOME</p>
                <div className="flex items-center space-x-[3px]">
                  <div className="w-[26px] bg-custom-gradient h-[2px]" />
                  <span className="gradient-text text-[10px]">&</span>
                  <div className="w-[26px] bg-custom-gradient h-[2px]" />
                </div>
                <p>ABROAD</p>
              </div>
            </div>
            {/* <div className="flex items-center space-x-4 mt-6">
              <img src="/assets/images/x.svg" alt="" />
              <img src="/assets/images/instagram.svg" alt="" />
              <img src="/assets/images/youtube.svg" alt="" />
              <img src="/assets/images/linkedin.svg" alt="" />
            </div> */}
          </div>
          <div className="col-span-4 flex flex-col md:flex-row gap-x-[100px] justify-end gap-y-6 mt-8 sm:mt-0">
            <div>
              <span className="subheading-20">Platform</span>
              <ul className="mt-5 sm:mt-7 flex flex-wrap sm:flex-col gap-x-8 gap-y-3 body-description">
                <Link to="/sign-in">
                  <li className="footer-link">Sign In</li>
                </Link>
                <Link to="/sign-up">
                  <li className="footer-link">Sign Up</li>
                </Link>
                <Link to="/about">
                  <li className="footer-link">About Us</li>
                </Link>
              </ul>
            </div>
            <div>
              <span className="subheading-20">Support</span>
              <ul className="mt-5 sm:mt-7 flex flex-wrap sm:flex-col gap-x-8 gap-y-3 body-description">
                <Link to="/faqs">
                  <li className="footer-link">FAQ</li>
                </Link>
                <Link to="mailto:support@homeandabroad.ng">
                  <li className="footer-link">Contact Us</li>
                </Link>
              </ul>
            </div>
            <div>
              <span className="subheading-20">Policies</span>
              <ul className="mt-5 sm:mt-7 flex flex-wrap sm:flex-col gap-x-8 gap-y-3 body-description">
                <Link to="/terms-and-conditions">
                  <li className="footer-link">Terms and Conditions</li>
                </Link>
                <Link to="/privacy-policy">
                  <li className="footer-link">Privacy Policy</li>
                </Link>
              </ul>
            </div>
          </div>
        </section>
        <div className="flex flex-wrap px-4 justify-center gap-x-4 mt-[50px] h3">
          <span>This is an initiative of</span>
          <a href="https://nidcom.gov.ng">
            <img src="/assets/images/nidcom.png" alt="" />
          </a>
          <span>powered by</span>
          <a href="https://sydani.org/">
            <img src="/assets/images/sydani.png" alt="" />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default HomeLayout;

export const Navbar = () => {
  // @ts-ignore

  const isAuthenticated = localStorage.getItem("access_token");
  let user = localStorage.getItem("user");
  if (user) user = JSON.parse(user);
  // @ts-ignore
  const source = user ? user["user-types"][0].type : "unauthenticated";

  return (
    <nav
      className="flex h-[94px] items-center px-4 sm:px-8 lg:px-10 xl:px-[30px] text-textBlack font-medium text-[14px] card-shadow"
      style={{ columnGap: 20, minHeight: 67 }}
    >
      <Link to="/">
        {/* <div className="flex items-center space-x-[7px]">
          <img className="h-11" src="/assets/images/logo.svg" alt="" />
          <div className="flex flex-col items-center text-[15.28px] font-semibold italic text-primary nav-logo">
            <p>HOME</p>
            <div className="flex items-center space-x-[3px]">
              <div className="w-[26px] bg-custom-gradient h-[2px]" />
              <span className="gradient-text text-[10px]">&</span>
              <div className="w-[26px] bg-custom-gradient h-[2px]" />
            </div>
            <p>ABROAD</p>
          </div>
        </div> */}
        <img className="w-[307px]" src="/assets/images/logo2.png" alt="" />
      </Link>
      <div className="hidden md:contents">
        <ul className="nav-links" style={{ alignItems: "center" }}>
          <CurrencySwitch />
          <Link to="/property-search">
            <li className="nav-link">Buy Now</li>
          </Link>
          <div className="flex px-[15px] flex-col items-center">
            <span className="text-[10px] text-red-500">Coming soon</span>
            <li className="">Mortgage</li>
          </div>
          <div className="flex px-[15px] flex-col items-center">
            <span className="text-[10px] text-red-500">Coming soon</span>
            <li className="">Installment</li>
          </div>
          {/* <Link to="/faqs">
            <li className="nav-link">FAQs</li>
          </Link> */}
        </ul>
        <div className="hidden xl:block relative">
          <SupportDropdown />
        </div>
        {/* <img
          className="hidden lg:block mr-10 ml-auto sm:ml-0"
          src="/assets/images/cart.svg"
          alt=""
        /> */}
        {isAuthenticated && user && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="hidden sm:block h-10 w-10 rounded-full bg-textGrey"></div>
            <div className="hidden sm:block">
              <UserDropdown />
            </div>
          </div>
        )}
        {!user && (
          <div className="flex gap-x-[25px] ml-6 lg:ml-0">
            <Link to="/sign-in">
              <button
                className="outline-button"
                style={{ whiteSpace: "nowrap" }}
              >
                SIGN IN
              </button>
            </Link>
            <Link to="/sign-up" className="hidden lg:block">
              <button
                className="filled-button"
                style={{ whiteSpace: "nowrap" }}
              >
                SIGN UP
              </button>
            </Link>
          </div>
        )}
      </div>
      <div className="md:hidden ml-auto">
        {/* @ts-ignore  */}
        <MobileNav source={source} />
      </div>
    </nav>
  );
};
