import { IconImagePlaceholder } from "../../../../common/components/LayoutIcons";
import { ChangeEvent, useState } from "react";
import { CancelRounded } from "@mui/icons-material";
import { TextField } from "@mui/material";

const UploadedItem = ({
  files,
  type,
  index,
  updateData,
  updateFormData,
  deleteItem,
}: {
  files: File[];
  type: "image" | "video";
  index: number;
  updateData: any;
  updateFormData: (evt: ChangeEvent<HTMLInputElement> | any) => void;
  deleteItem: () => void;
}) => {
  const [mouseHover, setMouseHover] = useState(false);

  const doConfirm = () => {
    const response = window.confirm("Remove file?");

    if (response) {
      deleteItem();
    }
  };

  return (
    <div className="uploaded-holder-outer">
      <div
        className="upload-holder"
        style={{ position: "relative" }}
        onMouseEnter={() => setMouseHover(true)}
        onMouseLeave={() => setMouseHover(false)}
      >
        {files?.length > index ? (
          <div>
            {type === "image" ? (
              <img src={URL.createObjectURL(files[index])} alt="Property" />
            ) : (
              <video src={URL.createObjectURL(files[index])} />
            )}
            {mouseHover && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: "#00000088",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  cursor: "pointer",
                }}
                onClick={doConfirm}
              >
                <CancelRounded style={{ fontSize: 50 }} />
              </div>
            )}
          </div>
        ) : (
          <IconImagePlaceholder />
        )}
      </div>
      <TextField
        name={`caption_${type}_${index + 1}`}
        placeholder={`Add ${type} label`}
        fullWidth
        defaultValue={updateData?.[`caption_${index + 1}`] || ""}
        onChange={updateFormData}
      />
    </div>
  );
};

export default UploadedItem;
