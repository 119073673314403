import React, { useEffect, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { GridArrowDownwardIcon } from "@mui/x-data-grid";
import {
  AccountBalance,
  Language,
  MoreHoriz,
  Person,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import {
  HeaderIconSearch,
  IconAttachmentPin,
  IconUploadArrowpage,
  IconVerifiedFull,
} from "../../../common/components/LayoutIcons";
import AdminDashboardLayout from "../../commonComponents/DashboardLayout";
import {
  IAPIResponse,
  IDashboardInfo,
  IInquiries,
  IListings,
  INotification,
  ITransactionDetails,
  IUserProfile,
  IUserType,
} from "../../../common/utils/interfaces";
import SingleListing from "../../../common/components/SingleListing";
import {
  MAX_DASHBOARD_LISTINGS_SELLER,
  USER_TYPES,
} from "../../../common/utils/constants";
import { getMyProfile } from "../../../../apis/accounts/user";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import {
  getMyListings,
  getSellerDashboardInfo,
} from "../../../../apis/listings/sellers";
import UseDebouncedValue from "../../../common/components/UseDebouncedValue";

type IReportCard = { title: string; value: number | string; extra?: any };

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.user as any);

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchTerm = UseDebouncedValue(searchQuery);

  const [userInfo, setUserInfo] = useState<IUserProfile>();
  const [userStatus, setUserStatus] = useState("");
  const [sellerProperties, setSellerProperties] = useState<IListings[]>([]);
  const [dashboardInfo, setDashboardInfo] = useState<IDashboardInfo | null>();
  const [isLoadingSellerProperties, setIsLoadingSellerProperties] =
    useState(false);

  useEffect(() => {
    loadSellerProperties();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    //  fetch reports
    const init = async () => {
      loadDashboardInfo();
      loadSellerDetails();

      loadSellerProperties();
    };

    const loadDashboardInfo = async () => {
      const response = await getSellerDashboardInfo();

      if (response?.status) {
        setDashboardInfo(response.data);
      }
    };

    //  fetch notifications
    //  fetch documents
    //

    init();
  }, []);

  useEffect(() => {
    const userStatus_ =
      user?.["user-types"]?.find(
        (userType: { type: IUserType; status: string }) =>
          userType.type === USER_TYPES.SELLER
      )?.status || "pending";

    setUserStatus(userStatus_);
  }, [user]);

  const loadSellerDetails = async () => {
    const response: IAPIResponse | undefined = await getMyProfile();

    if (response?.status) {
      setUserInfo(response.data);
      if (response.data?.status) {
        setUserStatus(response.data?.status);
      }
      localStorage.setItem("userInfo", JSON.stringify(response.data));
    }
  };

  const loadSellerProperties = async () => {
    setIsLoadingSellerProperties(true);
    const response: IAPIResponse | undefined = await getMyListings({
      search: debouncedSearchTerm,
    });
    setIsLoadingSellerProperties(false);

    if (response?.status) {
      setSellerProperties(response.data);
    }
  };

  const reportCards: IReportCard[] = [
    {
      title: "Approved Properties",
      value: dashboardInfo?.approved_listings || "-",
      /* extra: {
        change: {
          direction: "down",
          magnitude: "10%",
          text: "vs last month",
        },
      }, */
    },
    {
      title: "Pending Approvals",
      value: dashboardInfo?.pending_listings || "-",
      /* extra: {
        change: {
          direction: "up",
          magnitude: "40%",
          text: "vs last month",
        },
      }, */
    },
    {
      title: "Approval Status",
      value: "files submitted",
      extra: {
        change: {
          text: `${userStatus}${userStatus === "pending" ? "..." : ""}`,
        },
      },
    },
  ];
  const notifications: INotification[] = [
    /* { body: "Macron Ventures Heights submitted new documents", time: "2m ago" },
    { body: "Goolex Properties submitted new documents", time: "3d ago" },
    { body: "Criston Heights submitted new documents", time: "last week" },
    { body: "ForReal Real Estate submitted new documents", time: "last week" }, */
  ];
  const transactions: ITransactionDetails[] = [
    /* {
      name: "Bernard Oslo",
      businessName: "Monetery Heights",
      amount: "$312,908.45",
      datetime: "Apr 2, 2024",
    },
    {
      name: "Bernard Oslo",
      businessName: "Monetery Heights",
      amount: "$312,908.45",
      datetime: "Apr 2, 2024",
    },
    {
      name: "Bernard Oslo",
      businessName: "Monetery Heights",
      amount: "$312,908.45",
      datetime: "Apr 2, 2024",
    },
    {
      name: "Bernard Oslo",
      businessName: "Monetery Heights",
      amount: "$312,908.45",
      datetime: "Apr 2, 2024",
    }, */
  ];
  const inquiries: IInquiries[] = [
    {
      name: "Ibrahim Akinolu",
      type: "Missing Document",
      datetime: "1:41pm",
      url: "/owner",
    },
    {
      name: "Ibrahim Akinolu",
      type: "Missing Document",
      datetime: "1:41pm",
      url: "/type",
    },
    {
      name: "Ibrahim Akinolu",
      type: "Missing Document",
      datetime: "1:41pm",
      url: "/type",
    },
    {
      name: "Ibrahim Akinolu",
      type: "Missing Document",
      datetime: "1:41pm",
      url: "/type",
    },
  ];

  const Listings = () => {
    const navigate = useNavigate();

    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <Typography variant="h4">
            Listed properties{" "}
            {debouncedSearchTerm
              ? `(seaching '${debouncedSearchTerm}' ... )`
              : ""}
          </Typography>
          <Link to="/seller/listings" className="hover_underline">
            <Typography color="primary">SEE ALL</Typography>
          </Link>
        </div>
        <div className="listing-group">
          {!isLoadingSellerProperties &&
            sellerProperties
              ?.filter(
                (ppty, index: number) => index < MAX_DASHBOARD_LISTINGS_SELLER
              )
              ?.map((ppty) => (
                <SingleListing
                  section="seller"
                  listingData={ppty}
                  rightButtonAction={() => {
                    navigate("/seller/list-property", {
                      state: { listingId: ppty.lid, listingUrl: ppty.url },
                    });
                  }}
                />
              ))}

          {isLoadingSellerProperties ? (
            <>L O A D I N G . . .</>
          ) : (
            !sellerProperties.length && (
              <Typography variant="caption" className="no-records">
                No records yet
              </Typography>
            )
          )}
        </div>
      </div>
    );
  };

  const ReportCards = () => (
    <div className="report-cards">
      {reportCards?.map((card, index: number) => {
        const isStatus = card.title === "Approval Status";
        return (
          <div
            className={`report-card _shadow ${
              isStatus ? `verification-status _${userStatus}` : ""
            }`}
            key={index}
          >
            <div className="_top">
              <Typography variant="h5">{card.title}</Typography>
            </div>
            {isStatus ? (
              <div>
                <IconUploadArrowpage
                  className="_green-img"
                  style={{ width: 50 }}
                />
                <Typography
                  variant="h4"
                  style={{ color: "#686868", fontWeight: 400 }}
                >
                  {card.value}
                </Typography>
              </div>
            ) : (
              <Typography variant="h2">{card.value}</Typography>
            )}
            {card.extra?.change && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {card.extra?.change?.direction && (
                  <GridArrowDownwardIcon
                    style={{
                      color:
                        card.extra?.change?.direction === "up"
                          ? "green"
                          : card.extra?.change?.direction === "down"
                          ? "#CD0101"
                          : "inherit",
                    }}
                  />
                )}
                <Typography>
                  <span
                    style={{
                      marginLeft: 5,
                      color:
                        card.extra?.change?.direction === "up"
                          ? "green"
                          : card.extra?.change?.direction === "down"
                          ? "#CD0101"
                          : "inherit",
                    }}
                  >
                    {card.extra?.change?.magnitude}
                  </span>{" "}
                  <span className="_text">{card.extra?.change?.text}</span>
                </Typography>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );

  const SellerDetailsCard = () => {
    const visitsValue = {
      direction: "up",
      magnitude: "40%",
      text: "vs last month",
    };

    return (
      <div className="side-card _shadow" style={{ padding: "12px 24px" }}>
        <div className="side-card-main">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 22,
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 12,
              }}
            >
              <div className="_avatar" style={{ width: 60, height: 60 }}>
                {userInfo?.picture ? (
                  <img src={userInfo.picture} alt="DisplayPicture" />
                ) : (
                  <Person style={{ fontSize: 90 }} />
                )}
              </div>
              <div
                className="_details"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  variant="h5"
                  style={{ fontWeight: 400, marginBottom: "auto" }}
                >
                  {userInfo?.company_name}
                  {userInfo?.status === "approved" && (
                    <IconVerifiedFull
                      style={{ width: 16, display: "inline" }}
                    />
                  )}
                </Typography>
                <Typography variant="body2" style={{ fontWeight: 300 }}>
                  Real Estate Business
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 300, lineHeight: "16px" }}
                >
                  REG NO: {userInfo?.company_registration_number}
                </Typography>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <Typography variant="body2" style={{ fontWeight: 200 }}>
                Contact info:
              </Typography>
              <Typography>{userInfo?.email}</Typography>
              <div style={{ display: "flex", gap: 10 }}>
                <AccountBalance />
                <div>
                  <Typography>{userInfo?.address}</Typography>
                  <Typography>{userInfo?.city?.name}</Typography>
                  <Typography>{userInfo?.state?.name}</Typography>
                </div>
              </div>
              <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                <Language />
                <Typography>{userInfo?.website_url}</Typography>
              </div>
            </div>
            {/* temporarily hidden */}
            {/* <div>
              <Typography variant="body2" style={{ fontWeight: 200 }}>
                Profile visits
              </Typography>
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 15 }}
              >
                <Typography
                  style={{ fontSize: 28, fontWeight: 600, marginRight: 20 }}
                >
                  56
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {visitsValue.direction && (
                    <GridArrowDownwardIcon
                      style={{
                        color:
                          visitsValue.direction === "up"
                            ? "green"
                            : visitsValue.direction === "down"
                            ? "#CD0101"
                            : "inherit",
                      }}
                    />
                  )}
                  <Typography>
                    <span
                      style={{
                        marginLeft: 5,
                        color:
                          visitsValue.direction === "up"
                            ? "green"
                            : visitsValue.direction === "down"
                            ? "#CD0101"
                            : "inherit",
                      }}
                    >
                      {visitsValue.magnitude}
                    </span>{" "}
                    <span>{visitsValue.text}</span>
                  </Typography>
                </div>
              </div>
            </div> */}
            {/* temporarily hidden */}
            {/* <div>
              <Typography variant="body2" style={{ fontWeight: 200 }}>
                Portfolio score
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ flex: 1, fontWeight: 500 }}>
                  Your profile needs to be at least 70% complete to get good
                  views
                </Typography>
                <CustomCircleChart
                  percentage={67}
                  size={65}
                  color="#066D0E"
                  bgColor="#f8f8f8"
                />
              </div>
            </div> */}
          </div>
        </div>
        {/* temporarily hidden */}
        {/* <div className="side-card-footer" style={{ padding: 0, marginTop: 25 }}>
          <Button variant="contained" fullWidth>
            Edit Profile
          </Button>
        </div> */}
      </div>
    );
  };

  const RecentEnquiriesCard = () => (
    <div className="side-card _shadow" style={{ padding: "22px 23px" }}>
      <div
        className="side-card-header"
        style={{
          justifyContent: "center",
          padding: 0,
          height: "unset",
          marginBottom: 20,
        }}
      >
        <Typography variant="h5">Recent Inquiries</Typography>
      </div>
      <div
        className="side-card-main"
        style={{
          padding: 0,
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        {inquiries?.map((inquiry, index: number) => (
          <div
            key={index}
            className="_row"
            style={{ padding: 0, alignItems: "flex-start" }}
          >
            <div className="_avatar" style={{ width: 36, height: 36 }}>
              <Person style={{ fontSize: 48 }} />
            </div>
            <div>
              <Typography
                style={{
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                {inquiry.name}
                <IconAttachmentPin />
                <span
                  style={{ fontSize: 10, color: "#CECECE", fontWeight: 300 }}
                >
                  2
                </span>
              </Typography>
              <Typography style={{ fontWeight: 300, color: "#71717A" }}>
                {inquiry.type}
              </Typography>
            </div>
            <Typography
              style={{
                fontWeight: 300,
                color: "#CECECE",
                fontSize: 10,
                marginLeft: "auto",
              }}
            >
              {inquiry.datetime}
            </Typography>
          </div>
        ))}
      </div>
      <div className="side-card-footer" style={{ padding: 0, marginTop: 20 }}>
        <div className="_left"></div>
        <div className="_right">
          <Link to="/" className="hover_underline">
            <Typography style={{ fontWeight: 300, textTransform: "none" }}>
              See all tickets
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  );

  const TransactionsCard = () => (
    <div className="side-card _shadow" style={{ backgroundColor: "#ffffff" }}>
      <div
        className="side-card-header"
        style={{
          padding: 24,
          backgroundColor: "#F8F8F8",
          height: "unset",
        }}
      >
        <Typography>
          Transactions
          <span
            style={{
              fontSize: 10,
              color: "#71717A",
              display: "block",
              fontWeight: 300,
            }}
          >
            Recent transactions
          </span>
        </Typography>
        <Link to="/seller/transactions" className="hover_underline">
          <Typography
            color="primary"
            variant="body2"
            style={{ fontWeight: 300, textTransform: "none" }}
          >
            See all
          </Typography>
        </Link>
      </div>
      <div className="side-card-main" style={{ paddingTop: 0 }}>
        {transactions?.map((transaction, index: number) => (
          <div
            key={index}
            className="_row"
            style={{
              padding: "21px 25px",
              borderTop: "solid 1px #F1F1F1",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" style={{ color: "#000000" }}>
                {transaction.name}
              </Typography>
              <Typography variant="body2" style={{ color: "#71717A" }}>
                {transaction.businessName}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>{transaction.amount}</Typography>
              <Typography variant="body2" style={{ color: "#71717A" }}>
                {transaction.datetime}
              </Typography>
            </div>
            <MoreHoriz style={{ color: "grey", fontSize: 20 }} />
          </div>
        ))}
        {!transactions?.length && (
          <Typography variant="caption" className="no-records">
            No records yet
          </Typography>
        )}
      </div>
    </div>
  );

  return (
    <AdminDashboardLayout>
      <>
        <div className="page-central">
          <div>
            <div className="_flex" style={{ marginBottom: 24, gap: 24 }}>
              <div className="central-search _pc">
                <TextField
                  fullWidth
                  placeholder="Search listings"
                  value={searchQuery}
                  onChange={(evt) => setSearchQuery(evt.target.value)}
                />
                <HeaderIconSearch />
              </div>
              <Button
                variant="contained"
                onClick={() => navigate("/seller/list-property")}
                className="_pc"
                disabled={userStatus !== "approved"}
              >
                List property
              </Button>
              {/* temporarily hidden */}
              {/* <div className="icon-holder _pc">
                <IconNotificationBell />
              </div> */}
            </div>
            {!debouncedSearchTerm && <ReportCards />}
          </div>
          <div>
            <Listings />
          </div>
        </div>
        <div className="page-side">
          <SellerDetailsCard />
          {/* <RecentEnquiriesCard /> */}
          <TransactionsCard />
        </div>
      </>
    </AdminDashboardLayout>
  );
};

export default Dashboard;
