import React, { useEffect, useState } from "react";
import { SwiperSlide, Swiper, useSwiper } from "swiper/react";
import { Navigation } from "swiper/modules";
import { ListingInterface } from "../../../types";

interface Props {
  listing: ListingInterface;
  initialSlide: number | undefined;
  close: () => void;
}
const ImageCarousel: React.FC<Props> = ({ listing, initialSlide, close }) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  return (
    <Swiper
      initialSlide={initialSlide}
      onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex + 1)}
      className="mySwiper max-h-[90vh] w-[90vw] sm:w-[85vw] max-w-[1200px] fixed top-[50vh] z-50 translate-y-[-50%] "
    >
      <CustomSwiperButton />
      {listing.files.map((image) => (
        <SwiperSlide
          key={image.file}
          className="relative aspect-square sm:aspect-video z-10"
        >
          <div className="size-full aspect-video relative">
            {image.caption && (
              <div className="absolute z-[100] rounded-lg py-4 px-8 left-[50%] translate-x-[-50%] top-4 bg-black opacity-60">
                <p className="text-white">{image.caption}</p>
              </div>
            )}
            <div className="size-full z-[9] absolute top-0 left-0"></div>
            {image.file_type === "image" && (
              <img
                className="size-full mx-auto object-contain z-10 relative"
                src={image.file}
                alt=""
              />
            )}
            {image.file_type === "video" && (
              <video
                src={image.file}
                className="w-full h-full object-cover relative z-30 rounded-lg"
                controls
              >
                <source src={image.file} type="video/mp4" />
                <source src={image.file} type="video/webm" />
                <source src={image.file} type="video/ogg" />
              </video>
            )}
          </div>
        </SwiperSlide>
      ))}
      <div className="absolute z-[100] rounded-lg py-4 px-8 left-[50%] translate-x-[-50%] bottom-4 bg-black opacity-60">
        <p className="text-white">
          {currentIndex}/{listing.files.length}
        </p>
      </div>
    </Swiper>
  );
};

export default ImageCarousel;

const CustomSwiperButton = () => {
  const swiper = useSwiper();
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        swiper.slidePrev();
      } else if (event.key === "ArrowRight") {
        swiper.slideNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [swiper]);
  return (
    <div className="flex z-50 justify-between absolute w-full px-4 sm:px-8 top-[48%]">
      <img
        onClick={() => swiper.slidePrev()}
        className="scale-y-[1.2] sm:scale-y-[2] scale-x-[-1.2] sm:scale-x-[-2] bg-black bg-opacity-45 rounded-full cursor-pointer duration-150 hover:opacity-60"
        src="/assets/images/right-arrow.svg"
        alt=""
      />
      <img
        onClick={() => swiper.slideNext()}
        className="bg-black scale-[1.2] sm:scale-[2] bg-opacity-40 rounded-full cursor-pointer duration-150 hover:opacity-60"
        src="/assets/images/right-arrow.svg"
        alt=""
      />
    </div>
  );
};
