import { Link } from "react-router-dom"
import HomeLayout from "./Buyer/commonComponents/HomeLayout"

const NotFound = () => {
  return (
    <HomeLayout>
        <div className="flex flex-col gap-y-8 sm:flex-row pt-10 gap-x-16 justify-center  items-center">
            <div className="flex flex-col items-center">
                <h2 className="text-primary  font-semibold text-[36px]">ERROR!</h2>
                <p className="text-red-600 font-semibold mb-10 text-xl">Page Not Found</p>
                <Link to="/" className="filled-button !text-sm">Back to homepage</Link>
            </div>
            <img className="w-[400px] max-w-[70%] aspect-auto" src="/assets/images/error_image.png" alt="" />
        </div>  
    </HomeLayout>
  )
}

export default NotFound
