import * as React from "react";
import TableCell from "@mui/material/TableCell";
import { IconDocumentPage } from "../../../../../common/components/LayoutIcons";
import CustomTable, {
  HeadCell,
} from "../../../../../common/components/CustomTable/CustomTable";
import { IDeveloperDetails } from "../../../../../common/utils/interfaces";
import { Download } from "@mui/icons-material";
import { developerDocuments } from "../../../../../common/utils/constants";
import { Button } from "@mui/material";

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Document Title",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
];

export default function DocumentsTable({
  developerData,
}: {
  developerData: IDeveloperDetails | null;
}) {
  const tableTitle = { mainText: "Submitted Documents", subText: "" };

  const docsData = Object.keys(developerDocuments).map((doc: string) => ({
    name: doc,
    identifier: developerDocuments[doc],
    url: (developerData as any)?.[developerDocuments[doc]],
  }));

  const MappedTableRows = ({
    row,
    labelId,
    isItemSelected,
  }: {
    row: any;
    labelId: string;
    isItemSelected: boolean;
  }) => (
    <>
      <TableCell padding="checkbox">
        <div className="icon-circle-grey _small">
          <IconDocumentPage
            style={{
              width: 14,
              filter: "grayscale(1) brightness(2.5)",
            }}
          />
        </div>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.name}
      </TableCell>
      <TableCell align="center">
        {row.url ? (
          <a
            href={row.url}
            download={row.name}
            rel="noreferrer"
            target="_blank"
            style={{ marginLeft: "auto" }}
          >
            <Button
              variant="text"
              style={{
                padding: 4,
                minWidth: "unset",
                fontSize: 12,
              }}
            >
              <Download /> Download
            </Button>
          </a>
        ) : (
          <Button
            variant="text"
            disabled
            style={{
              padding: 4,
              minWidth: "unset",
              fontSize: 12,
              marginLeft: "auto",
            }}
          >
            <Download /> Download
          </Button>
        )}
      </TableCell>
    </>
  );

  return (
    <CustomTable
      tableType="card-table"
      tableTitle={tableTitle}
      headCells={headCells}
      rows={docsData}
      mappedTableRows={MappedTableRows}
      hideChangePerPage
      hidePagination
      headerEndIcon={<IconDocumentPage style={{ width: 25 }} />}
    />
  );
}
