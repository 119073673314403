import React from "react";

import no_image from "../../../assets/images/no-image.jpg";
import nav_icon_documents from "../../../assets/images/nav-icon-documents.svg";
import nav_icon_developers from "../../../assets/images/nav-icon-developers.svg";
import nav_icon_propertylisting from "../../../assets/images/nav-icon-propertylisting.svg";
import nav_icon_userbuyer from "../../../assets/images/nav-icon-userbuyer.svg";
import nav_icon_transactions from "../../../assets/images/nav-icon-transactions.svg";
import nav_icon_logout from "../../../assets/images/nav-icon-logout.svg";
import nav_icon_dashboard from "../../../assets/images/nav-icon-dashboard.svg";
import header_icon_search from "../../../assets/images/header-icon-search.svg";
import header_icon_filter from "../../../assets/images/header-icon-filter.svg";
import icon_more_menu from "../../../assets/images/icon-more-menu.svg";
import icon_push_notification from "../../../assets/images/icon-push-notification.svg";
import icon_notification_bell from "../../../assets/images/icon-notifications-bell.svg";
import icon_document_page from "../../../assets/images/icon-document-page.svg";
import icon_view_eye from "../../../assets/images/icon-view-eye.svg";
import pagination_icon_previous from "../../../assets/images/pagination-icon-previous.svg";
import pagination_icon_next from "../../../assets/images/pagination-icon-next.svg";
import logos_visa from "../../../assets/images/logos-visa.svg";
import icon_location_pointer from "../../../assets/images/icon-location-pointer.svg";
import icon_verified from "../../../assets/images/icon-verified.svg";
import icon_credit_card from "../../../assets/images/icon-credit-card.svg";
import polygon_corner from "../../../assets/images/polygon-corner.svg";
import pen_polygon_corner from "../../../assets/images/pen-polygon-corner.svg";
import icon_image_placeholder from "../../../assets/images/icon-image-placeholder.svg";
import icon_swipe_right from "../../../assets/images/icon-swipe-right.svg";
import rating_star_0 from "../../../assets/images/rating-star-0.svg";
import rating_star_1 from "../../../assets/images/rating-star-1.svg";
import icon_verified_full from "../../../assets/images/icon-verified-full.svg";
import icon_upload_arrowpage from "../../../assets/images/icon-upload-arrowpage.svg";
import icon_attachment_pin from "../../../assets/images/icon-attachment-pin.svg";
import mingcute_heart_line from "../../../assets/images/mingcute-heart-line.png";
import mingcute_heart_line_on from "../../../assets/images/mingcute-heart-line-on.svg";
import icon_add_cart from "../../../assets/images/icon-add-cart.svg";

export const NoImage = (prop: any) => {
  return (
    <img
      src={no_image}
      className={prop.className}
      style={prop.style}
      alt="NoImage"
    />
  );
};

export const NavIconDocuments = (prop: any) => {
  return (
    <img
      src={nav_icon_documents}
      className={prop.className}
      style={prop.style}
      alt="Documents"
    />
  );
};
export const NavIconDevelopers = (prop: any) => {
  return (
    <img
      src={nav_icon_developers}
      className={prop.className}
      style={prop.style}
      alt="Developers"
    />
  );
};
export const NavIconPropertyListing = (prop: any) => {
  return (
    <img
      src={nav_icon_propertylisting}
      className={prop.className}
      style={prop.style}
      alt="Propertylisting"
    />
  );
};
export const NavIconUserBuyer = (prop: any) => {
  return (
    <img
      src={nav_icon_userbuyer}
      className={prop.className}
      style={prop.style}
      alt="User & Buyer"
    />
  );
};
export const NavIconTransactions = (prop: any) => {
  return (
    <img
      src={nav_icon_transactions}
      className={prop.className}
      style={prop.style}
      alt="Transactions"
    />
  );
};
export const NavIconLogout = (prop: any) => {
  return (
    <img
      src={nav_icon_logout}
      className={prop.className}
      style={prop.style}
      alt="Logout"
    />
  );
};
export const NavIconDashboard = (prop: any) => {
  return (
    <img
      src={nav_icon_dashboard}
      className={prop.className}
      style={prop.style}
      alt="Dashboard"
    />
  );
};
export const HeaderIconSearch = (prop: any) => {
  return (
    <img
      src={header_icon_search}
      className={prop.className}
      style={prop.style}
      alt="Search"
    />
  );
};
export const HeaderIconFilter = (prop: any) => {
  return (
    <img
      src={header_icon_filter}
      className={prop.className}
      style={prop.style}
      alt="Filter"
    />
  );
};
export const IconMoreMenu = (prop: any) => {
  return (
    <img
      src={icon_more_menu}
      className={prop.className}
      style={prop.style}
      alt="More"
    />
  );
};
export const IconPushNotification = (prop: any) => {
  return (
    <img
      src={icon_push_notification}
      className={prop.className}
      style={prop.style}
      alt="Push notification"
    />
  );
};
export const IconNotificationBell = (prop: any) => {
  return (
    <img
      src={icon_notification_bell}
      className={prop.className}
      style={prop.style}
      alt="Notification"
    />
  );
};
export const IconDocumentPage = (prop: any) => {
  return (
    <img
      src={icon_document_page}
      className={prop.className}
      style={prop.style}
      alt="Document"
    />
  );
};
export const IconViewEye = (prop: any) => {
  return (
    <img
      src={icon_view_eye}
      className={prop.className}
      style={prop.style}
      alt="View"
    />
  );
};
export const PaginationIconPrevious = (prop: any) => {
  return (
    <img
      src={pagination_icon_previous}
      className={prop.className}
      style={prop.style}
      alt="Previous"
    />
  );
};
export const PaginationIconNext = (prop: any) => {
  return (
    <img
      src={pagination_icon_next}
      className={prop.className}
      style={prop.style}
      alt="Next"
    />
  );
};
export const LogoVisa = (prop: any) => {
  return (
    <img
      src={logos_visa}
      className={prop.className}
      style={prop.style}
      alt="VISA"
    />
  );
};
export const IconLocationPointer = (prop: any) => {
  return (
    <img
      src={icon_location_pointer}
      className={prop.className}
      style={prop.style}
      alt="Location"
    />
  );
};
export const IconVerified = (prop: any) => {
  return (
    <img
      src={icon_verified}
      className={prop.className}
      style={prop.style}
      alt="Verified"
    />
  );
};
export const IconCreditCard = (prop: any) => {
  return (
    <img
      src={icon_credit_card}
      className={prop.className}
      style={prop.style}
      alt="Card"
    />
  );
};
export const PolygonCorner = (prop: any) => {
  return (
    <img
      src={polygon_corner}
      className={prop.className}
      style={prop.style}
      alt="img"
    />
  );
};
export const PenPolygonCorner = (prop: any) => {
  return (
    <img
      src={pen_polygon_corner}
      className={prop.className}
      style={prop.style}
      alt="img"
    />
  );
};
export const IconImagePlaceholder = (prop: any) => {
  return (
    <img
      src={icon_image_placeholder}
      className={prop.className}
      style={prop.style}
      alt="Upload"
    />
  );
};
export const IconSwipeRight = (prop: any) => {
  return (
    <img
      src={icon_swipe_right}
      style={{ ...prop.style, ...(prop.left ? { rotate: "180deg" } : {}) }}
      alt="Swipe"
    />
  );
};
export const RatingStarOff = (prop: any) => {
  return (
    <img
      src={rating_star_0}
      className={prop.className}
      style={prop.style}
      alt="StarOn"
    />
  );
};
export const RatingStarOn = (prop: any) => {
  return (
    <img
      src={rating_star_1}
      className={prop.className}
      style={prop.style}
      alt="StarOff"
    />
  );
};
export const IconVerifiedFull = (prop: any) => {
  return (
    <img
      src={icon_verified_full}
      className={prop.className}
      style={prop.style}
      alt="Verified"
    />
  );
};
export const IconUploadArrowpage = (prop: any) => {
  return (
    <img
      src={icon_upload_arrowpage}
      className={prop.className}
      style={prop.style}
      alt="Upload"
    />
  );
};
export const IconAttachmentPin = (prop: any) => {
  return (
    <img
      src={icon_attachment_pin}
      className={prop.className}
      style={prop.style}
      alt="Attachment"
    />
  );
};
export const IconMingcuteHeartLineOff = (prop: any) => {
  return (
    <img
      src={mingcute_heart_line}
      className={prop.className}
      style={prop.style}
      alt="Like"
    />
  );
};
export const IconMingcuteHeartLineOn = (prop: any) => {
  return (
    <img
      src={mingcute_heart_line_on}
      className={prop.className}
      style={prop.style}
      alt="Like"
    />
  );
};
export const IconAddCart = (prop: any) => {
  return (
    <img
      src={icon_add_cart}
      className={prop.className}
      style={prop.style}
      alt="Cart"
    />
  );
};
